import { defineStore } from "pinia";
import {
  BasketUI,
  BasketProcessLineUI,
  BasketSubscriptionLineUI,
  BasketHardwareLineUI,
  BasketSwitchboardUserLineUI,
  BasketEmnSubscriptionLineUI,
  CombinedItem,
} from "../data-types";
import { BasketAccessoryLineUI } from "@telia/b2b-rest-client/dist/corp-product-order";
import { computed, ref } from "vue";
import { AnyBasketLineType, Basket, Line, LineType } from "../helpers/tracking/gaUtils";

export const useBasketStore = defineStore("basket", () => {
  const basket = ref<Basket>();
  const subscriptions = ref<BasketSubscriptionLineUI[]>([]);
  const hardwares = ref<BasketHardwareLineUI[]>([]);
  const switchboardUsers = ref<BasketSwitchboardUserLineUI[]>([]);
  const emnSubscriptions = ref<BasketEmnSubscriptionLineUI[]>([]);
  const processLines = ref<BasketProcessLineUI[]>([]);
  const combinedItems = ref<CombinedItem[]>([]);
  const accessories = ref<BasketAccessoryLineUI[]>([]);
  const allSubscriptionLines = ref<BasketSubscriptionLineUI[]>([]);
  const separatedInvoiceBasketLength = ref(0);
  const orderHasHardwareOrAccessories = ref(false);
  const unavailableSubscriptions = ref<string[]>([]);
  const bookedNumbers = ref<string[]>([]);

  const isEmptyBasket = computed(() => {
    return (
      basket.value &&
      basket.value.accessoryLines.length === 0 &&
      basket.value.lines.length === 0 &&
      basket.value.hardwareLines.length === 0 &&
      basket.value.emnLines.length === 0
    );
  });

  const totalOneTimeFee = computed(() => {
    if (basket.value) {
      return Number(basket.value.totalOneTimeFee);
    }
    return 0;
  });

  const totalRecurringFee = computed(() => {
    if (basket.value) {
      return Number(basket.value.totalRecurringFee);
    }
    return 0;
  });

  const startupFee = computed(() => {
    if (basket.value) {
      return Number(basket.value.startupFee);
    }
    return 0;
  });

  const allBasketItems = computed(() => {
    if (basket.value) {
      return [
        ...subscriptions.value,
        ...hardwares.value,
        ...accessories.value,
        ...combinedItems.value,
        ...emnSubscriptions.value,
      ];
    }
    return [];
  });

  const subscriptionProductsBoughtWithHardware = computed(() => {
    return (relationId: string) => {
      return basket.value?.hardwareLines.some(
        (hardwareLine) => hardwareLine.relationId === relationId
      );
    };
  });

  const allBasketItemsAsAnyBasketLineType = computed((): AnyBasketLineType<Line>[] => {
    if (basket.value) {
      return [
        ...basket.value.lines.map((line) => ({ ...line, lineType: LineType.SUBSCRIPTION })),
        ...basket.value.hardwareLines.map((line) => ({ ...line, lineType: LineType.HARDWARE })),
        ...basket.value.accessoryLines.map((line) => ({ ...line, lineType: LineType.ACCESSORY })),
        ...basket.value.processLines.map((line) => ({ ...line, lineType: LineType.PROCESS })),
        ...basket.value.switchboardUserLines.map((line) => ({
          ...line,
          lineType: LineType.SWITCHBOARD,
        })),
        ...basket.value.emnLines.map((line) => ({ ...line, lineType: LineType.EMN })),
      ];
    }
    return [];
  });

  const shouldShowDeliveryForPackage = computed(() => {
    return orderHasHardwareOrAccessories.value;
  });

  const physicalSubscriptionDelivery = computed(() =>
    allSubscriptionLines.value.filter((s) => s.simcard?.type === "SIMCARD" && !s.simcard.iccNumber)
  );

  const shouldShowEmnDeliveryInfo = computed(() => emnSubscriptions.value.length > 0);

  const activatedSubsByICC = computed(() =>
    allSubscriptionLines.value.filter((s) => s.simcard?.type === "SIMCARD" && s.simcard.iccNumber)
  );

  function setBasket(_basket: BasketUI) {
    basket.value = _basket as Basket;
    sortBasketItems(basket.value);
  }

  function sortBasketItems(basket: BasketUI) {
    subscriptions.value = [...basket.lines];
    emnSubscriptions.value = basket.emnLines;
    allSubscriptionLines.value = [...subscriptions.value];
    hardwares.value = [...basket.hardwareLines];
    accessories.value = basket.accessoryLines;
    switchboardUsers.value = basket.switchboardUserLines;
    processLines.value = basket.processLines;
    orderHasHardwareOrAccessories.value =
      hardwares.value.length > 0 || accessories.value.length > 0;
    separatedInvoiceBasketLength.value =
      subscriptions.value.length +
      emnSubscriptions.value.length +
      hardwares.value.length +
      accessories.value.length;

    const combinationItems: CombinedItem[] = [];
    for (let i = 0; i < subscriptions.value.length; i++) {
      const subItem = subscriptions.value[i];
      const matchingHardwareItems = hardwares.value.filter(
        (hardwareItem) => hardwareItem["relationId"] === subItem["relationId"]
      );

      if (matchingHardwareItems && matchingHardwareItems.length > 0) {
        const combinedItem = {
          subscription: subscriptions.value[i],
          hardware: matchingHardwareItems[0],
        };

        combinationItems.push(combinedItem);

        for (const matchedItem of matchingHardwareItems) {
          const index = hardwares.value.indexOf(matchedItem);
          if (index !== -1) {
            hardwares.value.splice(index, 1);
          }
        }

        subscriptions.value.splice(i, 1);
        i--;
      }
    }
    combinedItems.value = combinationItems;
  }
  function setUnavailbleSubscriptions(msisdn) {
    unavailableSubscriptions.value.push(msisdn);
  }
  function addBookedNumber(msisdn: string) {
    bookedNumbers.value.push(msisdn);
  }

  return {
    addBookedNumber,
    setBasket,
    setUnavailbleSubscriptions,
    subscriptionProductsBoughtWithHardware,
    unavailableSubscriptions,
    basket,
    bookedNumbers,
    subscriptions,
    hardwares,
    accessories,
    allSubscriptionLines,
    emnSubscriptions,
    switchboardUsers,
    combinedItems,
    totalOneTimeFee,
    totalRecurringFee,
    startupFee,
    separatedInvoiceBasketLength,
    allBasketItems,
    allBasketItemsAsAnyBasketLineType,
    shouldShowDeliveryForPackage,
    physicalSubscriptionDelivery,
    shouldShowEmnDeliveryInfo,
    processLines,
    activatedSubsByICC,
    isEmptyBasket,
  };
});
