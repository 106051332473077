<template>
  <div class="invoice-selector">
    <b2x-input-suggestions
      data-testid="invoice-selector"
      :label="t('INVOICE_ACCOUNT.HEADING')"
      :value="accountSearchInput"
      required
      selectFromSuggestionsOnly
      @inputValue="accountSearchInput = $event.detail"
      :suggestions="JSON.stringify(suggestions)"
      :error="showValidationError ? t('INVOICE_ACCOUNT.SELECT_INVOICE') : undefined"
      @suggestionSelected="selectAccount($event.detail)"
    />
    <div v-if="accountSearchInput.length" class="clear-search-icon">
      <telia-icon name="close" size="md" @click="clearAccountSearch" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref, watch } from "vue";
import { useValidationStore } from "../../store";
import { translateMixin } from "../../locale";
import "@telia/b2x-radio-card";

interface Props {
  allowPrePopulateNewInvoice?: boolean;
  newBillingAccounts: BillingAccount[];
  existingBillingAccounts: BillingAccount[];
}

interface BillingAccount {
  number: string;
  accountNumber: string;
  accountReference: string;
  subscriptionCount: number;
}

const emit = defineEmits<{ (e: "selectedInvoice", accountNumber: string | undefined): void }>();
const props = withDefaults(defineProps<Props>(), {
  allowPrePopulateNewInvoice: false,
});
const t = translateMixin.methods.t;
const validationStore = useValidationStore();
const accountSearchInput = ref<string>("");
const selectedAccount = ref<BillingAccount>();

const showValidationError = computed(() => {
  return validationStore.getInvoiceValidateState && !selectedAccount.value;
});
const amountOfNewAccounts = computed(() => {
  return props.newBillingAccounts.length;
});
const newBillingAccounts = computed(() => {
  return props.newBillingAccounts;
});

const suggestions = computed(() => {
  const formattedSearchInput = accountSearchInput.value.toLowerCase().replace(/\s/g, "");

  const newAccounts = props.newBillingAccounts
    .filter((account) => {
      const accountNumberMatch = account?.accountNumber
        ?.toLowerCase()
        .replace(/\s/g, "")
        .includes(formattedSearchInput);
      const accountReferenceMatch = account?.accountReference
        ?.toLowerCase()
        .replace(/\s/g, "")
        .includes(formattedSearchInput);
      return accountNumberMatch || accountReferenceMatch;
    })
    .map((account) => {
      return parseInvoiceText(
        account.accountNumber,
        account.accountReference,
        account.subscriptionCount,
        true
      );
    });

  const fetched = props.existingBillingAccounts
    .filter((account) => {
      const accountNumberMatch = account?.accountNumber
        ?.toLowerCase()
        .replace(/\s/g, "")
        .includes(formattedSearchInput);
      const accountReferenceMatch = account?.accountReference
        ?.toLowerCase()
        .replace(/\s/g, "")
        .includes(formattedSearchInput);
      return accountNumberMatch || accountReferenceMatch;
    })
    .map((account) => {
      return parseInvoiceText(
        account.accountNumber,
        account.accountReference,
        account.subscriptionCount
      );
    });

  return [...newAccounts, ...fetched];
});

watch(
  () => amountOfNewAccounts.value,
  () => {
    if (!props.allowPrePopulateNewInvoice) return;
    const index = amountOfNewAccounts.value - 1;
    selectAccount(
      newBillingAccounts.value[index].accountNumber,
      newBillingAccounts.value[index].accountReference,
      true
    );
  }
);

function selectAccount(number: string, reference?: string, _new?: boolean) {
  const accountNumberMatch = (number || "").match(/\d+/);

  if (accountNumberMatch) {
    const accountNumber = accountNumberMatch[0];

    accountSearchInput.value = parseInvoiceText(number, reference, undefined, _new);

    const allAccounts = [...props.newBillingAccounts, ...props.existingBillingAccounts];

    selectedAccount.value = allAccounts.find((account) => account.accountNumber === accountNumber);

    if (selectedAccount.value?.accountNumber) {
      const isNewBillingAccount = props.newBillingAccounts.some(
        (account) => account.number === selectedAccount.value?.number
      );
      if (isNewBillingAccount) {
        //TODO if selected inovice is new get its address
      }
      emit("selectedInvoice", selectedAccount.value.accountNumber);
    } else {
      emit("selectedInvoice", undefined);
    }
  }
}
function parseInvoiceText(number: string, reference?: string, count?: number, _new?: boolean) {
  let baseText = `${number}`;
  if (reference) {
    baseText = `${baseText} / ${reference} `;
  }

  if (count) {
    baseText = `${baseText} (${count} ${t("INVOICE_ACCOUNT.SUBSCRIPTIONS")})`;
  }

  if (_new) {
    baseText = `${baseText} ${t("INVOICE_ACCOUNT.NEW_ACCOUNT")}`;
  }

  return baseText;
}

function clearAccountSearch() {
  accountSearchInput.value = "";
  selectedAccount.value = undefined;
  emit("selectedInvoice", undefined);
}
</script>

<style lang="scss" scoped>
@import "node_modules/@teliads/components/foundations/colors/variables";
@import "node_modules/@teliads/components/foundations/spacing/variables";
.invoice-selector {
  position: relative;
  .clear-search-icon {
    position: absolute;
    color: $telia-gray-500;
    top: 70%;
    right: calc($telia-spacing-32 * -1);
    transform: translateY(-50%);
    cursor: pointer;

    :hover {
      color: $telia-black;
    }
  }
}
.invoice-selector-cards {
  display: flex;
  flex-direction: column;
  gap: $telia-spacing-20;
  padding: $telia-spacing-12;
  max-height: 46rem;
  overflow-y: scroll;
}
</style>
