<template>
  <div class="invoice-address-summary">
    <div v-if="isNewAccount" class="invoice-address-summary__badge-wrapper">
      <telia-badge variant="information">{{ t("INVOICE_ACCOUNT.NEW") }}</telia-badge>
    </div>
    <telia-p>
      <strong>{{ t("INVOICE_ACCOUNT.INFORMATION") }}</strong>
    </telia-p>
    <telia-p v-if="selectedAccountAddress.accountReference">
      {{ selectedAccountAddress.accountReference }}
    </telia-p>
    <telia-p>{{ selectedAccountAddress.accountNumber }}</telia-p>
    <telia-p>{{ selectedAccountAddress.address }}</telia-p>
    <telia-p>{{ selectedAccountAddress.postalCode }}, {{ selectedAccountAddress.city }}</telia-p>
    <div class="invoice-address-summary__delivery-method">
      <telia-p v-if="selectedAccountAddress.deliveryMethod === 'POSTAL'">
        {{ t("INVOICE_ACCOUNT.DELIVERY_METHOD_POSTAL") }}
      </telia-p>
      <telia-p v-else>
        {{ t("INVOICE_ACCOUNT.DELIVERY_METHOD_MAIL") }}
      </telia-p>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { GetBillingAccountDTO } from "../../data-types";
import { translateMixin } from "../../locale";

interface Props {
  isNewAccount?: boolean;
  selectedAccountAddress: AccountAddress;
}

interface AccountAddress {
  accountNumber: string;
  accountReference: string;
  address: string;
  postalCode: string;
  city: string;
  deliveryMethod: GetBillingAccountDTO["deliveryMethod"];
}

const props = withDefaults(defineProps<Props>(), {
  isNewAccount: false,
});
const t = translateMixin.methods.t;
</script>

<style lang="scss" scoped>
@import "node_modules/@teliads/components/foundations/spacing/variables";
.invoice-address-summary {
  padding: $telia-spacing-12 0 $telia-spacing-24 0;
  &__delivery-method {
    margin-top: $telia-spacing-16;
  }
  &__badge-wrapper {
    padding-bottom: $telia-spacing-12;
  }
}
</style>
