<template>
  <div :class="{ 'hardware-item': true, 'hardware-item__combined-item': !combinedItem }">
    <div class="hardware-item__image-wrapper">
      <img v-if="item.product.imageUrl" :src="`https://www.telia.se${item.product.imageUrl}`" />
      <div v-else class="hardware-item__icon-wrapper">
        <telia-icon name="smartphone" size="lg" />
      </div>
    </div>
    <div class="hardware-item__item-info">
      <telia-p>
        <strong>{{ item.product.name }}</strong>
      </telia-p>
      <div>
        <telia-p v-if="isBasketHardwareLine(item) && item.product.memoryGb">
          {{ t("CART.MEMORY_GB", { memory: item.product.memoryGb }) }}
          {{ item.product?.color }}
        </telia-p>
      </div>
      <div class="hardware-item__price-wrapper">
        <div v-if="!combinedItem" class="hardware-item__price-wrapper__line">
          <telia-p>
            <strong>{{ formatCurrency(recurringFee) }}{{ t("CART.PER_MONTH") }} </strong>
          </telia-p>
        </div>
        <div class="hardware-item__price-wrapper__line">
          <telia-p>
            <strong>{{ formatCurrency(oneTimeFee) }}</strong>
          </telia-p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { BasketHardwareLineUI, isBasketHardwareLine } from "../../data-types";
import { translate as t } from "@telia/b2b-i18n";
import { formatCurrency } from "../../helpers/currencyFormat";
import { CartHardwareItemProps } from "src/data-types/Cart";
import { BasketAccessoryLineUI } from "@telia/b2b-rest-client/dist/corp-product-order";

interface Props {
  item: BasketHardwareLineUI | BasketAccessoryLineUI;
  combinedItem?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  combinedItem: false,
});

const oneTimeFee = computed(() => {
  return Number(props.item?.oneTimeFee);
});
const recurringFee = computed(() => {
  return Number(props.item?.recurringFee);
});
</script>

<style lang="scss" scoped>
@import "node_modules/@teliads/components/foundations/spacing/variables";
@import "node_modules/@teliads/components/foundations/colors/variables";
.hardware-item {
  display: flex;
  gap: $telia-spacing-12;
  position: relative;
  padding: $telia-spacing-12 0;

  &__image-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 6rem;
    img {
      max-width: 6rem;
      max-height: 5rem;
      object-fit: contain;
    }
  }

  &__item-info {
    flex: 1;
  }
  &__price-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    &__line {
      display: flex;
    }
  }
  &__addons-wrapper {
    display: flex;
    flex-direction: column;
    gap: $telia-spacing-4;
  }
  &__addon-list {
    display: flex;
    justify-content: space-between;
  }
  &__combined-item {
    padding-bottom: $telia-spacing-24;
    border-bottom: solid 1px $telia-gray-200;
  }
  &__invoice-info {
    display: flex;
    align-items: center;
    gap: $telia-spacing-12;
  }
}
</style>
