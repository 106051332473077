<template>
  <div class="delivery-contact-info" t-id="delivery-contact-info">
    <form
      v-if="active"
      data-testid="contact-form"
      class="delivery-contact-info-active"
      @submit.prevent="handleCompleteStep"
    >
      <telia-text-input
        :label="t('DELIVERY_CONTACT_INFO.FIRST_NAME')"
        :value="contactInfo.firstName"
        @input="contactInfo.firstName = $event.target.value"
        required
      />
      <telia-text-input
        :label="t('DELIVERY_CONTACT_INFO.LAST_NAME')"
        :value="contactInfo.lastName"
        required
        @input="contactInfo.lastName = $event.target.value"
      />
      <telia-text-input
        inputmode="email"
        :label="t('DELIVERY_CONTACT_INFO.MAIL')"
        :value="contactInfo.emailAddress"
        required
        :error-message="formErrors.emailAddress ? 'Invalid email' : undefined"
        @input="contactInfo.emailAddress = $event.target.value"
      />
      <telia-text-input
        :label="t('DELIVERY_CONTACT_INFO.PHONE_NUMBER')"
        inputmode="tel"
        :value="contactInfo.phone"
        required
        :error-message="formErrors.phone ? 'Invalid phone number' : undefined"
        @input="contactInfo.phone = $event.target.value"
      />
      <telia-text-input
        :label="t('DELIVERY_CONTACT_INFO.REFERENCE')"
        :additional="t('DELIVERY_CONTACT_INFO.REFERENCE_ASSISTIVE')"
        @input="contactInfo.reference = $event.target.value"
      />
      <div class="next-step-button-wrapper">
        <telia-button v-if="!isEditing" variant="secondary" size="md" type="submit">
          {{ t("STEPPER.NEXT_STEP") }}
        </telia-button>
        <telia-button v-if="isEditing" variant="secondary" size="sm" type="submit">
          {{ t("STEPPER.SAVE") }}
        </telia-button>
      </div>
    </form>

    <div v-else-if="completed" class="summary-wrapper">
      <div>
        <telia-p>{{ contactInfo.firstName }} {{ contactInfo.lastName }}</telia-p>
        <telia-p>{{ contactInfo.emailAddress }}</telia-p>
        <telia-p>{{ contactInfo.phone }}</telia-p>
        <telia-p>{{ contactInfo.reference }}</telia-p>
      </div>
      <div>
        <telia-button variant="secondary" size="sm" @click="handleEditContact">
          {{ t("STEPPER.EDIT") }}
        </telia-button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { translate as t } from "@telia/b2b-i18n";
import { onMounted, ref, computed, watch } from "vue";
import { getLoggedInUserInfo } from "@telia/b2b-logged-in-service";
import { useDeliveryInfoStore } from "../../store";
import { validatePhoneNumber, validateEmail } from "../../helpers/validationService";
import { ContactDetails } from "../../data-types";

interface Props {
  active: boolean;
  isEditing: boolean;
}

defineProps<Props>();
const emit = defineEmits(["completeStep", "handleEditContact"]);

const contactInfo = ref<ContactDetails>({
  firstName: "",
  lastName: "",
  emailAddress: "",
  phone: "",
});
const completed = ref<boolean>(false);
const deliveryStore = useDeliveryInfoStore();
const formErrors = ref({
  emailAddress: false,
  phone: false,
});

watch(
  () => contactInfo.value.phone,
  () => {
    formErrors.value.phone = false;
  }
);
watch(
  () => contactInfo.value.emailAddress,
  () => {
    formErrors.value.emailAddress = false;
  }
);

onMounted(async () => {
  try {
    const response = await getLoggedInUserInfo();
    contactInfo.value = {
      firstName: response.firstName,
      lastName: response.lastName,
      emailAddress: response.mail,
      phone: response.phone,
    };
  } catch (e) {
    console.log(e);
  }
});

async function handleCompleteStep() {
  const isValid = await validateForm();
  if (!isValid) return;
  deliveryStore.setContactDetails(contactInfo.value);
  emit("completeStep");
  completed.value = true;
}

async function validateForm() {
  const [validEmail, validPhoneNumber] = await Promise.all([
    validateEmail(contactInfo.value.emailAddress),
    validatePhoneNumber(contactInfo.value.phone),
  ]);
  formErrors.value.emailAddress = !validEmail;
  formErrors.value.phone = !validPhoneNumber;
  return validEmail && validPhoneNumber;
}

function handleEditContact() {
  emit("handleEditContact");
}
</script>

<style lang="scss" scoped>
@import "node_modules/@teliads/components/foundations/spacing/variables";
.delivery-contact-info-active {
  display: flex;
  flex-direction: column;
  gap: $telia-spacing-24;
}
.summary-wrapper {
  display: flex;
  flex-direction: column;
  gap: $telia-spacing-24;
}
</style>
