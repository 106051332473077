<template>
  <div class="emn-item">
    <div class="emn-item__item-info">
      <telia-p>
        <strong>{{ item.product.name }}</strong>
      </telia-p>
      <div>
        <telia-p>{{ t("CART.EMN.SITE", { site: item.product.siteName || "" }) }}</telia-p>
        <telia-p v-if="item.product.ipRangeStart">
          {{ t("CART.EMN.IP_RANGE_START", { ipRangeStart: item.product.ipRangeStart }) }}
        </telia-p>
        <telia-p>{{ t("CART.EMN.QUANTITY", { quantity: item.quantity }) }}</telia-p>
      </div>
      <div class="emn-item__price-wrapper">
        <div class="emn-item__price-wrapper__line">
          <telia-p>
            <strong>{{ formatCurrency(recurringFee) }}{{ t("CART.PER_MONTH") }} </strong>
          </telia-p>
        </div>
        <div v-if="oneTimeFee" class="emn-item__price-wrapper__line">
          <telia-p>
            <strong>{{ formatCurrency(oneTimeFee) }}</strong>
          </telia-p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { translate as t } from "@telia/b2b-i18n";
import { BasketEmnSubscriptionLineUI } from "../../data-types";
import { formatCurrency } from "../../helpers/currencyFormat";

interface Props {
  item: BasketEmnSubscriptionLineUI;
}

const props = defineProps<Props>();

const oneTimeFee = computed(() => {
  return Number(props.item.oneTimeFee);
});
const recurringFee = computed(() => {
  return Number(props.item.recurringFee);
});
</script>

<style lang="scss" scoped>
@import "node_modules/@teliads/components/foundations/spacing/variables";
@import "node_modules/@teliads/components/foundations/colors/variables";
.emn-item {
  display: flex;
  gap: $telia-spacing-12;
  position: relative;
  padding: $telia-spacing-12 0;

  &__image-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 6rem;
    img {
      max-width: 6rem;
      max-height: 5rem;
      object-fit: contain;
    }
  }

  &__item-info {
    flex: 1;
  }
  &__price-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    &__line {
      display: flex;
    }
  }
}
</style>
