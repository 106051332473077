<template>
  <div class="gathered-invoice-selection">
    <InvoiceSelector
      :new-billing-accounts="deliveryStore.getNewBillingAccounts"
      :existing-billing-accounts="deliveryStore.getExistingBillingAccounts"
      allow-pre-populate-new-invoice
      @selectedInvoice="handleSelectedAccount"
    />
    <InvoiceAddressSummary
      v-if="deliveryStore.selectedBillingAccount"
      :is-new-account="isNewAccount"
      :selected-account-address="deliveryStore.selectedBillingAccount"
    />
  </div>
</template>
<script lang="ts" setup>
import { useDeliveryInfoStore, useValidationStore } from "../../store";
import InvoiceSelector from "./InvoiceSelector.vue";
import InvoiceAddressSummary from "./InvoiceAddressSummary.vue";
import { computed } from "vue";

const deliveryStore = useDeliveryInfoStore();
const validationStore = useValidationStore();

const isNewAccount = computed(() => {
  return deliveryStore.getNewBillingAccounts.some(
    (account) => account.accountNumber === deliveryStore.selectedBillingAccount?.accountNumber
  );
});

function handleSelectedAccount(accountNumber: string | undefined) {
  deliveryStore.setBillingAccountId(accountNumber);
  if (accountNumber !== undefined) {
    validationStore.setValidationError(undefined);
  }
}
</script>

<style lang="scss" scoped>
@import "node_modules/@teliads/components/foundations/spacing/variables";
@import "node_modules/@teliads/components/foundations/colors/variables";

.gathered-invoice-selection {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: $telia-spacing-24;
  padding-bottom: $telia-spacing-12;
  .clear-search-icon {
    position: absolute;
    color: $telia-gray-500;
    top: 70%;
    right: $telia-spacing-12;
    transform: translateY(-50%);
    cursor: pointer;

    :hover {
      color: $telia-black;
    }
  }
  &__invoice-address {
    &__delivery-method {
      margin-top: $telia-spacing-16;
    }
  }
}
.invoice-wrapper {
  display: flex;
  flex-direction: column;
  gap: $telia-spacing-16;
  max-height: 42rem;
  overflow-y: scroll;
  padding: $telia-spacing-8 $telia-spacing-24 $telia-spacing-8 $telia-spacing-8;
}
</style>
