<template>
  <form @submit.prevent="createNewInvoiceAccount">
    <div class="new-invoice-account">
      <telia-heading variant="title-200" tag="h2">
        {{ t("INVOICE_ACCOUNT.CREATE_INVOICE_ACCOUNT") }}
      </telia-heading>
      <telia-text-input
        :label="t('INVOICE_ACCOUNT.INVOICE_NAME')"
        additional="Fakturareferens visas på adressraden på fakturan och underlättar framtida orderhantering i My Business."
        @input="handleReferenceInput($event.target.value)"
      />
      <b2b-address-search
        t-id="edit-shipping-address__address-search"
        :label="t('INVOICE_ACCOUNT.INVOICE_ADDRESS')"
        :scope-id="scopeId"
        :debounce-timeout-ms="300"
        required
        manual-address-enabled
        :error="addressErrorMessage"
        @modeChanged="handleModeChanged($event.detail)"
        @addressSelected="handleAddressSelected($event.detail)"
      />
      <div class="new-invoice-account__delivery-selection">
        <b2x-radio-card
          :label="t('INVOICE_ACCOUNT.LETTER')"
          :value="DeliveryType.POSTAL"
          :checked="deliveryTypeSelected === DeliveryType.POSTAL"
          @change="setDeliveryType($event.target.value)"
        />
        <b2x-radio-card
          :label="t('INVOICE_ACCOUNT.EMAIL')"
          :value="DeliveryType.EMAIL"
          :checked="deliveryTypeSelected === DeliveryType.EMAIL"
          @change="setDeliveryType($event.target.value)"
        />
      </div>
      <div class="new-invoice-account__by-email" v-if="deliveryTypeSelected === DeliveryType.EMAIL">
        <b2x-input-suggestions
          :label="t('INVOICE_ACCOUNT.EMAIL')"
          :suggestions="JSON.stringify(emails)"
          :value="deliveryContact.email"
          required
          :error="emailErrorMessage"
          @inputValue="selectInvoiceEmail($event.detail)"
          @suggestionSelected="selectInvoiceEmail($event.detail)"
        />
        <telia-p>
          {{ t("INVOICE_ACCOUNT.CONSENT_EMAIL") }}
          <telia-link href="/foretag/om/integritetspolicy" target="_blank">
            {{ t("INVOICE_ACCOUNT.CONSENT_LINK") }}
          </telia-link>
        </telia-p>
        <telia-p>
          <strong>{{ t("INVOICE_ACCOUNT.EMAIL_CONTACTS") }}</strong>
        </telia-p>
        <telia-text-input
          :label="t('INVOICE_ACCOUNT.CONTACT_PERSON')"
          required
          @input="setContactPerson($event.target.value)"
        />
        <telia-text-input
          :label="t('INVOICE_ACCOUNT.PHONE_NUMBER')"
          required
          @input="setPhoneNumber($event.target.value)"
          :error-message="phoneErrorMessage"
        />
      </div>
      <Notification
        v-if="createAccountError"
        status="warning"
        :heading="t('INVOICE_ACCOUNT.ERROR_MESSAGE.TITLE')"
        :body="t('INVOICE_ACCOUNT.ERROR_MESSAGE.BODY')"
      />
      <div class="new-invoice-account__button-wrapper">
        <telia-button variant="secondary" size="md" @click="emit('cancel-new-invoice')">
          {{ t("INVOICE_ACCOUNT.CANCEL") }}
        </telia-button>
        <b2x-loading-button
          variant="primary"
          size="md"
          type="submit"
          :is-loading="isLoading"
          :loading-label="t('INVOICE_ACCOUNT.CREATING_ACCOUNT')"
        >
          {{ t("INVOICE_ACCOUNT.CREATE_ACCOUNT") }}
        </b2x-loading-button>
      </div>
    </div>
  </form>
</template>

<script lang="ts" setup>
import { AddressInformation } from "@telia/b2b-address-search";
import { corpBillingAccounts } from "@telia/b2b-rest-client";
import { AddBillingAccountDTO } from "@telia/b2b-rest-client/dist/corp-billing-accounts";
import { AddBillingAccountRequestDTO } from "@telia/b2b-rest-client/dist/corp-billing-accounts/models/AddBillingAccountRequestDTO";
import { computed, onMounted, reactive, ref, toRefs, watch } from "vue";
import {
  trackCreateNewBillingAccount,
  trackInvoiceAddressInput,
} from "../../helpers/stepperTracking";
import { validateAndFormatPhoneNumber, validateEmail } from "../../helpers/validationService";
import { translateMixin } from "../../locale";
import Notification from "../ui-components/Notification.vue";

interface Props {
  scopeId: string;
  tscid: string;
  customerName?: string;
  customerNumber: string;
}

enum DeliveryType {
  POSTAL = "POSTAL",
  EMAIL = "EMAIL",
}
type DeliveryTypeSelected = DeliveryType.POSTAL | DeliveryType.EMAIL;
const props = defineProps<Props>();
const { scopeId, tscid, customerName, customerNumber } = toRefs(props);
const emit = defineEmits<{
  (e: "account-created", newAccount: AddBillingAccountDTO): void;
  (e: "cancel-new-invoice"): void;
}>();

const t = translateMixin.methods.t;
const deliveryTypeSelected = ref<DeliveryTypeSelected>();
const emails = ref<string[]>([]);
const isLoading = ref<boolean>(false);
const manualAddressInput = ref<boolean>(false);
const formattedPhoneNumber = ref<string>();
const createAccountError = ref<boolean>(false);
const selectedAddress = reactive({
  address: "",
  city: "",
  postalCode: "",
});
const errorFields = reactive<{
  email: boolean;
  phoneNumber: boolean;
  selectedAddress: boolean;
}>({
  email: false,
  phoneNumber: false,
  selectedAddress: false,
});

const accountReference = ref("");
const deliveryContact = reactive<{
  contactPerson: string | undefined;
  email: string | undefined;
  phoneNumber: string | undefined;
}>({
  contactPerson: undefined,
  email: undefined,
  phoneNumber: undefined,
});

watch(
  () => deliveryContact.email,
  () => {
    errorFields.email = false;
  }
);

watch(
  () => deliveryContact.phoneNumber,
  () => {
    errorFields.phoneNumber = false;
  }
);

const emailErrorMessage = computed(() => {
  return errorFields.email ? t("INVOICE_ACCOUNT.INVALID_EMAIL") : undefined;
});
const phoneErrorMessage = computed(() => {
  return errorFields.phoneNumber ? t("INVOICE_ACCOUNT.INVALID_PHONE_NUMBER") : undefined;
});
const addressErrorMessage = computed(() => {
  return errorFields.selectedAddress ? t("INVOICE_ACCOUNT.INVALID_ADDRESS") : undefined;
});
const validAddress = computed(() => {
  return Object.values(selectedAddress).every((val) => val);
});
onMounted(async () => {
  emails.value = await corpBillingAccounts.BillingAccountsControllerService.getBillingAccountEmails(
    scopeId.value,
    tscid.value
  );
});

function setDeliveryType(selection: DeliveryType) {
  if (selection === DeliveryType.POSTAL) {
    deliveryContact.contactPerson = undefined;
    deliveryContact.email = undefined;
    deliveryContact.phoneNumber = undefined;
  }

  deliveryTypeSelected.value = selection;
}

function handleReferenceInput(reference: string) {
  accountReference.value = reference;
}
function handleModeChanged(event: "MANUAL" | "SEARCH") {
  manualAddressInput.value = event === "MANUAL";
  trackInvoiceAddressInput(manualAddressInput.value);
}

function handleAddressSelected(address: AddressInformation) {
  if (!address) return;
  errorFields.selectedAddress = false;
  selectedAddress.address = address.streetName + " " + address.streetNumber ?? "";
  selectedAddress.city = address.city ?? "";
  selectedAddress.postalCode = address.postalCode ?? "";
}
function selectInvoiceEmail(email: string) {
  deliveryContact.email = email;
}
function setContactPerson(value: string) {
  deliveryContact.contactPerson = value;
}
function setPhoneNumber(value) {
  deliveryContact.phoneNumber = value;
}

const invoiceAccountPayload = computed((): AddBillingAccountRequestDTO => {
  return {
    category: "MOBILE",
    name: customerName.value || "",
    customerNumber: customerNumber.value,
    accountReference: accountReference.value,
    ...selectedAddress,
    ...deliveryContact,
  } as AddBillingAccountRequestDTO;
});

async function createNewInvoiceAccount() {
  if (!validAddress.value) return;
  createAccountError.value = false;
  isLoading.value = true;
  if (deliveryTypeSelected.value === DeliveryType.EMAIL) {
    const response = await validateAndFormat();
    if (!response.valid) {
      isLoading.value = false;
      return;
    }
  }
  const temp = deliveryContact.phoneNumber;
  deliveryContact.phoneNumber = formattedPhoneNumber.value;
  try {
    const billingAccountResponse =
      await corpBillingAccounts.BillingAccountsControllerService.addBillingAccount(
        scopeId.value,
        tscid.value,
        invoiceAccountPayload.value
      );
    if (billingAccountResponse && deliveryTypeSelected.value) {
      emit("account-created", billingAccountResponse);
      isLoading.value = false;
      trackCreateNewBillingAccount(true, manualAddressInput.value);
    }
  } catch {
    createAccountError.value = true;
    deliveryContact.phoneNumber = temp;
    isLoading.value = false;
    trackCreateNewBillingAccount(false, manualAddressInput.value);
  }
}
async function validateAndFormat() {
  try {
    const numberResponse = deliveryContact.phoneNumber
      ? await validateAndFormatPhoneNumber(deliveryContact.phoneNumber)
      : { valid: false, formattedNumber: undefined };

    const validEmailResponse = deliveryContact.email
      ? await validateEmail(deliveryContact.email)
      : { valid: false };

    errorFields.selectedAddress = Object.values(selectedAddress).some((val) => !val);
    errorFields.email = !validEmailResponse;
    errorFields.phoneNumber = !numberResponse.valid;

    formattedPhoneNumber.value = numberResponse.formattedNumber?.replace("+", "") ?? "";

    return { valid: validEmailResponse && numberResponse.valid };
  } catch {
    return { valid: false };
  }
}
</script>

<style lang="scss" scoped>
@import "node_modules/@teliads/components/foundations/spacing/variables";
.new-invoice-account {
  display: flex;
  flex-direction: column;
  gap: $telia-spacing-32;

  &__delivery-selection {
    display: flex;
    gap: $telia-spacing-24;
    width: 100%;
    > * {
      flex: 1;
    }
  }
  &__button-wrapper {
    display: flex;
    justify-content: flex-end;
    gap: $telia-spacing-24;
  }
  &__by-email {
    display: flex;
    flex-direction: column;
    gap: $telia-spacing-24;
  }
}
</style>
