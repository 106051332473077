import { corpProductOrder } from "@telia/b2b-rest-client";
import { CheckoutRequestUI } from "@telia/b2b-rest-client/dist/corp-product-order";
import { ref } from "vue";
import { trackConfirmOrder } from "../helpers/orderTracking";
import { useBasketStore, useDeliveryInfoStore } from "../store";
import { trackPurchase } from "../helpers/tracking/gaHelper";

export function useSubmitOrder() {
  const isSubmitting = ref(false);
  const checkoutFailed = ref(false);
  const basketId = ref<string>();
  const orderNumber = ref<string>();
  const basketStore = useBasketStore();

  const checkout = async (scopeId: string, tscid: string, payload: CheckoutRequestUI) => {
    try {
      isSubmitting.value = true;
      const basketItems = [...basketStore.allBasketItemsAsAnyBasketLineType];

      const response = await corpProductOrder.OrderControllerService.checkout(
        scopeId,
        tscid,
        payload
      );
      basketId.value = response.basketId;
      orderNumber.value = response.orderNumber;
      trackConfirmOrder(
        orderNumber,
        basketStore.basket,
        payload.billingAccountIds?.length ?? 1,
        isManualShippingAddress()
      );
      trackPurchase(orderNumber.value as string, basketItems);
      window.location.href = `/foretag/mybusiness/${scopeId}/bestall/${tscid}/din-bestallning/${basketId.value}`;
    } catch (e: any) {
      checkoutFailed.value = true;
    } finally {
      isSubmitting.value = false;
    }
  };

  return { isSubmitting, checkout, checkoutFailed, basketId };
}

function isManualShippingAddress() {
  const deliveryInfoStore = useDeliveryInfoStore();
  return deliveryInfoStore.getAddressMode === "MANUAL";
}
