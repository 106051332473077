import { defineStore } from "pinia";
import { ref } from "vue";
import { Error } from "../data-types";

export const useErrorHandlingStore = defineStore("error-handling-store", () => {
  const setupError = ref<Error | undefined>();
  const checkoutError = ref<Error | undefined>();

  function setError(error: Error) {
    setupError.value = error;
  }
  function setCheckoutError(error: Error) {
    checkoutError.value = error;
  }

  return {
    setError,
    setCheckoutError,
    setupError,
    checkoutError,
  };
});
