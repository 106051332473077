<template>
  <div :class="{ 'subscription-item': true, 'subscription-item__top-padding': !combinedItem }">
    <div class="subscription-item__image-wrapper">
      <img :src="simcardImg" />
    </div>
    <div class="subscription-item__item-info">
      <div class="subscription-item__item-info__title-wrapper">
        <telia-p>
          <strong>{{ item.product.name }}</strong>
        </telia-p>
        <span
          :class="{
            'subscription-item__item-info__alert-icon': true,
            'subscription-item__item-info__unavailable-number': numberIsUnavailable,
          }"
        >
          <telia-icon v-if="numberIsUnavailable" name="alert" size="lg" />
        </span>
      </div>
      <telia-p>{{ t(`CART.${item.product.operation}`) }}</telia-p>
      <telia-p v-if="item.msisdn">
        {{ item?.user?.name }}
        <span :class="{ 'subscription-item__item-info__unavailable-number': numberIsUnavailable }">
          {{ formatMsisdn(item?.msisdn) }}
        </span>
      </telia-p>
      <telia-p>
        <strong>{{ switchboard?.product?.name }}</strong>
      </telia-p>
      <div class="subscription-item__price">
        <telia-p>
          <telia-p>
            <strong>{{ formatCurrency(recurringFee) }}{{ t("CART.PER_MONTH") }}</strong>
          </telia-p>
        </telia-p>
      </div>
      <div v-if="numberTransfer && numberTransfer.currentMsisdn" class="number-transfer-wrapper">
        <span> <telia-icon name="shuffle" size="lg" /></span>
        <span>
          <telia-p>
            <strong>{{ t("CART.PORTIN_TRANSFER") }}</strong>
          </telia-p>
          <telia-p>
            {{ t("CART.TRANSFER_OF") }} {{ formatMsisdn(numberTransfer?.currentMsisdn) }}
          </telia-p>
          <telia-p
            v-if="numberTransfer.desiredDeliveryDate"
            t-id="number-swap-container__temporary-msisdn"
            >{{ t("CART.TRANSFER_DATE", { date: numberTransfer.desiredDeliveryDate }) }}</telia-p
          >
        </span>
      </div>
      <AccordionItem id="1" :open="true" data-test-id="show-more-accordion">
        <SubscriptionConfigList
          :addons="item.addons"
          :commitment-time="props.item.product?.commitment"
          :combined-item="combinedItem"
          :one-time-fee="item.oneTimeFee"
          :increased-fee="increasedFee"
          :total-price="totalPrice"
          :switchboard="switchboard"
          :datasims="item.datasims"
          :mds="item.mds"
          :simcard="item.simcard?.type"
        />
      </AccordionItem>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import simcardImg from "../../assets/simcard.png";
import AccordionItem from "../ui-components/AccordionItem.vue";
import { translate as t } from "@telia/b2b-i18n";
import SubscriptionConfigList from "./SubscriptionConfigList.vue";
import { useBasketStore } from "../../store";
import { formatMsisdn } from "../../helpers/number-formatter.service";
import { formatCurrency } from "../../helpers/currencyFormat";
import { BasketSubscriptionLineUI } from "@telia/b2b-rest-client/dist/corp-product-order";
interface Props {
  item: BasketSubscriptionLineUI;
  combinedItem?: boolean;
  increasedFee?: string;
  hardwared?: string;
}
const props = withDefaults(defineProps<Props>(), {
  combinedItem: false,
});

const basketStore = useBasketStore();
const oneTimeFee = computed(() => {
  return Number(props.item.oneTimeFee);
});
const recurringFee = computed(() => {
  return Number(props.item.recurringFee);
});

const totalPrice = computed(() => {
  if (props.item?.product?.commitment) {
    return String(
      Math.floor(Number(props.item?.recurringFee) * Number(props.item?.product?.commitment))
    );
  }
  return "";
});

const switchboard = computed(() => {
  return basketStore.switchboardUsers.find(
    (switchboard) => switchboard.relationId === props.item?.relationId
  );
});
const numberTransfer = computed(() => {
  return basketStore.processLines.find((process) => process.relationId === props.item?.relationId);
});
const numberIsUnavailable = computed(() => {
  return basketStore.unavailableSubscriptions.find((msisdn) => msisdn === props.item?.msisdn);
});
</script>

<style lang="scss" scoped>
@import "node_modules/@teliads/components/foundations/spacing/variables";
@import "node_modules/@teliads/components/foundations/colors/variables";
.subscription-item {
  display: flex;
  gap: $telia-spacing-12;
  position: relative;
  border-bottom: solid 1px $telia-gray-200;
  padding-bottom: $telia-spacing-24;
  &__image-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    img {
      max-width: 6rem;
      object-fit: contain;
    }
  }
  &__top-padding {
    padding-top: $telia-spacing-24;
  }

  &__item-info {
    flex: 1;
    &__title-wrapper {
      display: flex;
      justify-content: space-between;
    }
    &__alert-icon {
      padding-left: $telia-spacing-12;
    }

    &__unavailable-number {
      color: $telia-orange-500;
    }
  }
  &__price {
    display: flex;
    justify-content: flex-end;
  }
  &__addons-wrapper {
    display: flex;
    flex-direction: column;
    gap: $telia-spacing-4;
  }
  &__combined-items {
    display: flex;
    flex-direction: column;
  }
  &__inline {
    display: flex;
    justify-content: space-between;
  }
  &__invoice-info {
    display: flex;
    align-items: center;
    gap: $telia-spacing-12;
  }
}
.number-transfer-wrapper {
  display: flex;
  gap: $telia-spacing-12;
  align-items: center;
  padding: $telia-spacing-12;
}
</style>
