<template>
  <div class="invoice-account" t-id="invoice-account">
    <div v-if="active" class="invoice-account-active" data-testid="invoice-account-step">
      <InvoiceConfig v-show="!newInvoiceIsActive" @createNewInvoice="newInvoiceIsActive = true" />
      <NewInvoiceAccount
        v-if="newInvoiceIsActive && scopeId && tscid && customerNumber"
        :scope-id="scopeId"
        :tscid="tscid"
        :customer-number="customerNumber"
        :customer-name="customerName"
        @cancel-new-invoice="newInvoiceIsActive = false"
        @account-created="handleNewAccountCreated"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, computed } from "vue";
import { corpBillingAccounts } from "@telia/b2b-rest-client";
import { useUserConfigStore, useDeliveryInfoStore } from "../../store";
import NewInvoiceAccount from "./NewInvoiceAccount.vue";
import InvoiceConfig from "./InvoiceConfig.vue";
import { AddBillingAccountDTO } from "@telia/b2b-rest-client/dist/corp-billing-accounts";

defineProps({
  active: {
    type: Boolean,
    required: true,
  },
});

const store = useDeliveryInfoStore();
const userConfigStore = useUserConfigStore();
const newInvoiceIsActive = ref<boolean>(false);

onMounted(async () => {
  fetchBillingAccounts();
});
const scopeId = computed(() => userConfigStore.getScopeId);
const tscid = computed(() => userConfigStore.getTscid);
const customerNumber = computed(() => userConfigStore.getCustomerNumber);
const customerName = computed(() => userConfigStore.getCustomer?.name ?? "");

function handleNewAccountCreated(newBillingAccount: AddBillingAccountDTO) {
  store.addNewBillingAccount(newBillingAccount);
  newInvoiceIsActive.value = false;
}

async function fetchBillingAccounts() {
  if (!scopeId.value || !tscid.value) return;

  let sortedAccounts;
  try {
    const response =
      await corpBillingAccounts.BillingAccountsControllerService.getBillingAccountsForTscid(
        scopeId.value,
        tscid.value
      );
    if (!response) return;

    sortedAccounts = response
      .filter(
        (account) =>
          account.customerNumber === customerNumber.value && !account.singleSubscriptionBilling
      )
      .sort((a, b) => (b.subscriptionCount ?? 0) - (a.subscriptionCount ?? 0));
  } catch (e) {
    console.log(e);
  }

  if (!sortedAccounts) return;
  store.setExistingBillingAccounts(sortedAccounts);
}
</script>

<style lang="scss" scoped>
@import "node_modules/@teliads/components/foundations/spacing/variables";
.invoice-account-active {
  display: flex;
  flex-direction: column;
  gap: $telia-spacing-32;
}

.summary-wrapper {
  display: flex;
  flex-direction: column;
  gap: $telia-spacing-24;
}
.summary-skeleton-wrapper {
  display: flex;
  flex-direction: column;
  gap: $telia-spacing-8;
  .summary-skeleton {
    width: 20rem;
    height: 1.8rem;
  }
}
</style>
