import * as analytics from "@telia/b2b-web-analytics-wrapper";
import { useUserConfigStore } from "../store";

export function trackCheckoutStep(initiateStep: number) {
  const userConfigStore = useUserConfigStore();
  const payload = {
    // pa: 'checkout_option',
    // ta: 'MyBusiness',
    // cos: this.index + 2,
    // col: 'start_' + (this.index + 2),
    cd4: "MCO", // teliaAnalytics requires cd4 'ProductOffer', look into this
    cd9: "MCO", // teliaAnalytics requires cd9 'Hierarchy ID', look into this
    cd94: getAgreementType(),
  };

  analytics.trackEvent(
    analytics.category.SALESFLOW,
    `${analytics.action.CHECKOUT_STEP}${initiateStep}`,
    "start_" + initiateStep,
    "0",
    Object.keys(payload).map((key) => ({
      type: key,
      value: payload[key],
    }))
  );
}

export function trackDeliveryAddressInput(isManualInput: boolean) {
  const userConfigStore = useUserConfigStore();
  const labelPrefix = "shipping ";
  const payload = {
    cd4: "MCO",
    cd9: "MCO",
    cd94: getAgreementType(),
  };
  analytics.trackEvent(
    analytics.category.SALESFLOW,
    analytics.action.CLICK,
    isManualInput ? labelPrefix + "address_manual" : labelPrefix + "address_search",
    "0",
    Object.keys(payload).map((key) => ({
      type: key,
      value: payload[key],
    }))
  );
}

export function trackInvoiceAddressInput(isManualInput: boolean) {
  analytics.trackEvent(
    analytics.category.INVOICE_ACCOUNTS,
    analytics.action.CLICK,
    isManualInput ? "address_manual" : "address_search"
  );
}

export function trackInitiateCreateNewBillingAccount() {
  analytics.trackEvent(analytics.category.INVOICE_ACCOUNTS, analytics.action.INITIATED, "0");
}

export function trackCreateNewBillingAccount(completed: boolean, isManualInput: boolean) {
  analytics.trackEvent(
    analytics.category.INVOICE_ACCOUNTS,
    completed ? analytics.action.COMPLETED : analytics.action.ERROR,
    isManualInput ? "address_manual" : "address_search"
  );
}

function getAgreementType() {
  const userConfigStore = useUserConfigStore();
  if (userConfigStore.hasNfa === undefined) {
    return "Unkown";
  }
  return userConfigStore.hasNfa ? "New Frame Agreement" : "MFA/RAM";
}
