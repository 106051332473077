import {
  BasketAccessoryLineUI,
  BasketHardwareLineUI,
  BasketProcessLineUI,
  BasketSubscriptionLineUI,
  BasketSwitchboardUserLineUI,
  BasketUI,
  GetBasketAccessoryProductUI,
  GetBasketAddonUI,
  GetBasketDatasimUI,
  GetBasketHardwareProductUI,
  GetBasketMdsUI,
  GetBasketSubscriptionProductUI,
  BasketEmnSubscriptionLineUI,
} from "@telia/b2b-rest-client/dist/corp-product-order";
import { Product, Ga4Product } from "@telia/b2b-ecommerce-tracking";
import Big from "big.js";
import {
  ACCESSORY_LISTNAME,
  EMN_LISTNAME,
  HARDWARE_LISTNAME,
  SUBSCRIPTION_LISTNAME,
} from "./constants";

export interface CommonPayload {
  id: string;
  name: string;
  category: string;
  quantity: string;
  variant: string;
  couponCode: string;
  discount: string;
}

export type LineProductItem =
  | GetBasketHardwareProductUI
  | GetBasketAccessoryProductUI
  | GetBasketAddonUI
  | GetBasketDatasimUI
  | GetBasketMdsUI;

export type Line =
  | SubscriptionLine
  | HardwareLine
  | ProcessLine
  | AccessoryLine
  | SwitchboardUserLine
  | EmnSubscriptionLine;

export type DeepRequired<T> = { [K in keyof T]: DeepRequired<T[K]> } & Required<T>;
export type Basket = DeepRequired<BasketUI>;
export type HardwareLine = DeepRequired<BasketHardwareLineUI>;
export type AccessoryLine = DeepRequired<BasketAccessoryLineUI>;
export type SubscriptionLine = DeepRequired<BasketSubscriptionLineUI>;
export type ProcessLine = DeepRequired<BasketProcessLineUI>;
export type SwitchboardUserLine = DeepRequired<BasketSwitchboardUserLineUI>;
export type EmnSubscriptionLine = DeepRequired<BasketEmnSubscriptionLineUI>;

export enum LineType {
  SUBSCRIPTION = "SUBSCRIPTION",
  HARDWARE = "HARDWARE",
  PROCESS = "PROCESS",
  ACCESSORY = "ACCESSORY",
  SWITCHBOARD = "SWITCHBOARD",
  EMN = "EMN",
}

export type AnyBasketLineType<T extends Line> = T & {
  lineType: LineType;
};

export function isAccessoryProduct(
  basketProduct: LineProductItem
): basketProduct is GetBasketAccessoryProductUI {
  return (basketProduct as GetBasketAccessoryProductUI).subCategory === "accessories";
}
export function isHardwareProduct(
  basketProduct: LineProductItem
): basketProduct is GetBasketHardwareProductUI {
  return (basketProduct as GetBasketAccessoryProductUI).subCategory === "mobiledevice";
}

export function isSubscriptionProduct(
  basketProduct: LineProductItem
): basketProduct is GetBasketSubscriptionProductUI {
  return !!(basketProduct as GetBasketSubscriptionProductUI).operation;
}

export function isSubscriptionLine(
  line: AnyBasketLineType<Line>
): line is AnyBasketLineType<SubscriptionLine> {
  return line.lineType === LineType.SUBSCRIPTION;
}

export function isSwitchboardLineType(
  line: AnyBasketLineType<Line>
): line is AnyBasketLineType<SwitchboardUserLine> {
  return line.lineType === LineType.SWITCHBOARD;
}

export function isHardwareLineType(
  line: AnyBasketLineType<Line>
): line is AnyBasketLineType<HardwareLine> {
  return line.lineType === LineType.HARDWARE;
}

export function isAccessoryLineType(
  line: AnyBasketLineType<Line>
): line is AnyBasketLineType<AccessoryLine> {
  return line.lineType === LineType.ACCESSORY;
}

export function isEmnSubscriptionLine(
  line: AnyBasketLineType<Line>
): line is AnyBasketLineType<EmnSubscriptionLine> {
  return line.lineType === LineType.EMN;
}

export function totalProductPrice(products: Ga4Product[]): number {
  return products
    .map((product) => product.price)
    .reduce((acc, current) => Big(acc).add(Big(current)), Big("0"))
    .toNumber();
}

export function productPrice(product: {
  oneTimeFee: string;
  recurringFee: string;
  commitment?: number;
}): string {
  const reccuringFeeForCommitment = Big(product.recurringFee).mul(product.commitment ?? 0);
  return Big(product.oneTimeFee).add(reccuringFeeForCommitment).toFixed(2);
}

export function buildLinePayload(
  line: AnyBasketLineType<Line>,
  boughtWithHardware: boolean = false
): Ga4Product[] {
  const products = [line.product];
  if (isSubscriptionLine(line)) {
    products.push(...line.addons, ...line.datasims);
    if (line.mds) {
      products.push(line.mds);
    }
  } else if (isSwitchboardLineType(line)) {
    products.push(...line.addons);
  }
  return products.map((product) => buildProductPayload(product, line.lineType, boughtWithHardware));
}

export function buildProductPayload(
  product: LineProductItem,
  lineType: LineType,
  boughtWithHardware: boolean
): Ga4Product {
  let brand = "Telia";
  if (isAccessoryProduct(product) || isHardwareProduct(product)) {
    brand = product.name.replace(/ .*/, ""); // TODO get brand from backend, now assume first word is the brand
  }
  const itemListName = (): Ga4Product["itemListName"] => {
    switch (lineType) {
      case LineType.SUBSCRIPTION:
      case LineType.SWITCHBOARD:
      case LineType.PROCESS:
        return boughtWithHardware ? HARDWARE_LISTNAME : SUBSCRIPTION_LISTNAME;
        break;
      case LineType.ACCESSORY:
        return ACCESSORY_LISTNAME;
      case LineType.HARDWARE:
        return HARDWARE_LISTNAME;
      case LineType.EMN:
        return EMN_LISTNAME;
    }
  };
  return {
    brand,
    name: product.name,
    id: product.productCode,
    category: product.category,
    subCategory: product.subCategory,
    quantity: 1,
    onetimePrice: Number(product.oneTimeFee),
    recurringPrice: Number(product.recurringFee),
    newOrExtend:
      isSubscriptionProduct(product) && product.operation === "EXTEND_SUBSCRIPTION"
        ? "extension"
        : "new",
    couponCode: "not set",
    price: Number(productPrice(product)),
    variant: isHardwareProduct(product) ? `${product.memory} ${product.color}` : "",
    itemListName: itemListName(),
  };
}
