<template>
  <div>
    <div class="invoice-per-product">
      <div>
        <telia-heading variant="title-100" tag="h2">{{ name }}</telia-heading>
        <slot />
      </div>
      <div>
        <InvoiceSelector
          data-testid="product-invoice-selector"
          @selectedInvoice="handleSelectedAccount"
          :new-billing-accounts="deliveryStore.newBillingAccounts"
          :existing-billing-accounts="deliveryStore.existingAccounts"
        />
      </div>
    </div>
    <InvoiceAddressSummary
      data-testid="address-summary"
      v-if="selectedAccount"
      :is-new-account="isNewAccount"
      :selected-account-address="selectedAccount"
    />
  </div>
</template>

<script lang="ts" setup>
import { computed } from "vue";
import { useDeliveryInfoStore } from "../../store";
import InvoiceSelector from "./InvoiceSelector.vue";
import InvoiceAddressSummary from "./InvoiceAddressSummary.vue";

interface Props {
  name: string;
  productId: string;
}

const props = defineProps<Props>();

const deliveryStore = useDeliveryInfoStore();

const isNewAccount = computed(() => {
  return deliveryStore.getNewBillingAccounts.some(
    (account) => account.accountNumber === selectedAccount.value?.accountNumber
  );
});

const selectedAccount = computed(() => {
  const accountIdForProduct = deliveryStore.getBillingAccountIds.find(
    (productBillingAccount) => productBillingAccount.itemBasketName === props.productId
  );
  return deliveryStore.allAccounts.find(
    (account) => account.accountNumber === accountIdForProduct?.billingAccountId
  );
});

function handleSelectedAccount(account: string | undefined) {
  if (account === undefined && props.productId) {
    deliveryStore.removeProductFromBillingAccountIds(props.productId);
    return;
  }

  if (!account) return;
  deliveryStore.setBillingAccountIds(props.productId, account);
}
</script>

<style lang="scss" scoped>
@import "node_modules/@teliads/components/foundations/colors/variables";
@import "node_modules/@teliads/components/foundations/spacing/variables";
.invoice-per-product {
  display: flex;
  gap: $telia-spacing-12;
  border-top: solid 1px $telia-gray-300;
  padding: $telia-spacing-32 0;
  :first-child {
    flex: 3;
  }
  :nth-child(2) {
    flex: 4;
  }
}
</style>
