import { defineStore } from "pinia";
import { computed, ref, watchEffect } from "vue";
import { Step } from "../data-types";
import { trackCheckoutStep } from "../helpers/stepperTracking";
import { trackCheckoutStepGa4 } from "../helpers/tracking/gaHelper";
import { useBasketStore } from "./BasketStore";
import { useDeliveryInfoStore } from "./DeliveryInfoStore";

export const useStepperStore = defineStore("stepper-store", () => {
  const currentStep = ref<Step>(Step.DeliveryAddress);
  const editStep = ref<number>();
  const basketStore = useBasketStore();

  function nextStep() {
    trackCheckoutStepGa4(currentStep.value, basketStore.allBasketItemsAsAnyBasketLineType);
    currentStep.value = currentStep.value += 1;
    trackCheckoutStep(currentStep.value);
  }

  function setEditStep(step: Step) {
    editStep.value = step;
  }

  function unsetEditStep() {
    editStep.value = undefined;
  }

  const isEditing = computed<(step: number) => boolean>(() => {
    return (step: number) => step === editStep.value;
  });

  return {
    currentStep,
    editStep,
    Step,
    nextStep,
    setEditStep,
    unsetEditStep,
    isEditing,
  };
});
