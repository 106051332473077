<template>
  <div :class="{ 'step-item': props?.step, 'step-item-active': active }">
    <div
      :class="{
        'step-number': props?.step,
        'step-number-active': active,
        'step-number-completed': (completed && !active) || invoiceComplete,
      }"
    >
      <telia-icon v-if="completed || invoiceComplete" name="check-bold" size="sm" />
      <telia-p v-else>
        <strong>{{ step }}</strong>
      </telia-p>
    </div>
    <div :class="{ 'content-wrapper': true, 'content-wrapper-active': active }">
      <telia-p>
        <strong>{{ heading }}</strong>
      </telia-p>
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useStepperStore, useValidationStore } from "../../store";
const props = defineProps({
  heading: {
    type: String,
  },
  step: {
    type: Number,
  },
  active: {
    type: Boolean,
  },
});
const stepperStore = useStepperStore();
const validationStore = useValidationStore();
const completed = computed(() => {
  if (!props?.step) return false;
  return !props.active && props.step < stepperStore.currentStep;
});
const invoiceComplete = computed(() => {
  return validationStore.getInvoiceCompleteStatus && props.step === 3;
});
</script>

<style lang="scss" scoped>
@import "node_modules/@teliads/components/foundations/spacing/variables";
@import "node_modules/@teliads/components/foundations/colors/variables";
.step-item {
  display: flex;
  gap: $telia-spacing-24;
  background-color: $telia-white;
  padding: $telia-spacing-16 $telia-spacing-16;
  border-radius: 10px;
}
.step-item-active {
  display: flex;
  gap: $telia-spacing-24;
  background-color: $telia-white;
  padding: $telia-spacing-32 $telia-spacing-48 $telia-spacing-32 $telia-spacing-16;
}
.step-number {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $telia-gray-50;
  color: $telia-black;
  height: $telia-spacing-32;
  width: $telia-spacing-32;
  border-radius: 90%;
}
.step-number-active {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $telia-purple-700;
  color: $telia-white;
  height: $telia-spacing-32;
  width: $telia-spacing-32;
  border-radius: 90%;
}
.step-number-completed {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $telia-white;
  color: $telia-purple-700;
  border: solid 2px $telia-purple-700;
  height: $telia-spacing-32;
  width: $telia-spacing-32;
  border-radius: 90%;
}
.content-wrapper {
  display: flex;
  flex-direction: column;
  gap: $telia-spacing-4;
  flex: 1;
}
.content-wrapper-active {
  gap: $telia-spacing-24;
}
</style>
