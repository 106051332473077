<template>
  <div class="delivery-address" t-id="delivery-address">
    <div v-if="active" class="delivery-address-active">
      <div v-if="!render" class="delivery-address__skeletons" data-testid="skeleton-loaders">
        <telia-skeleton />
        <telia-skeleton />
      </div>
      <telia-text-input
        v-if="render"
        t-id="delivery-address-input"
        data-testid="delivery-organisation-input"
        :label="t('DELIVERY_ADDRESS.COMPANY_NAME')"
        :value="address?.businessName"
        required
        @input="setOrganisationName($event.target.value)"
      />
      <KeepAlive>
        <b2b-address-search
          v-if="render"
          t-id="edit-shipping-address__address-search"
          :label="t('DELIVERY_ADDRESS.ADDRESS')"
          :scope-id="scopeId"
          :debounce-timeout-ms="300"
          :defaultAddress="JSON.stringify(defaultAddress)"
          manual-address-enabled
          required
          @modeChanged="handleModeChanged($event.detail)"
          @addressSelected="handleAddressSelected($event.detail)"
        />
      </KeepAlive>

      <div v-if="showPackageReceiver" class="delivery-address__package-info">
        <div class="delivery-address__package-info__receiver-input">
          <div>
            <telia-p>
              <strong>{{ t("DELIVERY_ADDRESS.PACKAGE") }}</strong>
            </telia-p>
            <telia-p>
              {{ t("DELIVERY_ADDRESS.HARDWARE_ACCESSORIES_DELIVERY_INFO") }}
            </telia-p>
          </div>
          <telia-text-input
            :label="t('DELIVERY_ADDRESS.RECEIVER')"
            @input="setAttention($event.target.value)"
          />
          <div>
            <telia-p>
              <strong>{{ t("DELIVERY_ADDRESS.LETTER") }}</strong>
            </telia-p>
            <telia-p>
              {{ t("DELIVERY_ADDRESS.SIMCARD_DELIVERY_INFO") }}
            </telia-p>
            <telia-p>{{ t("DELIVERY_ADDRESS.DELIVERY_RECEIVER") }}</telia-p>
          </div>
        </div>
        <div>
          <div
            v-for="subscription in subscriptionDeliveries"
            :key="subscription.id"
            class="delivery-address__inline"
          >
            <telia-icon name="email" size="md" />
            <telia-p v-if="subscription.msisdn">
              {{ subscription.user?.name }}
              {{ formatMsisdn(subscription.msisdn) }}
            </telia-p>
          </div>
        </div>
      </div>
      <div v-if="showEmnDeliveryInfo">
        <telia-p>
          <strong>{{ t("DELIVERY_ADDRESS.EMN_DELIVERY") }}</strong>
        </telia-p>
        <telia-p>
          {{ t("DELIVERY_ADDRESS.EMN_DELIVERY_INFO") }}
        </telia-p>
      </div>
      <div v-if="activatedSubscriptionsIcc?.length" class="delivery-address__icc-info">
        <telia-p>
          <strong>{{ t("DELIVERY_ADDRESS.ACTIVATED_ICC_INFO") }}</strong>
        </telia-p>
        <div v-for="subscription in activatedSubscriptionsIcc" :key="subscription.id">
          <div class="delivery-address__inline">
            <telia-icon name="simcard" size="md" />
            <div>
              <telia-p v-if="subscription.msisdn">
                {{ subscription.user?.name }} {{ formatMsisdn(subscription.msisdn) }}
              </telia-p>
              <telia-p class="delivery-address__color-gray">
                {{ t("DELIVERY_ADDRESS.ICC", { icc: subscription.simcard?.iccNumber ?? "" }) }}
              </telia-p>
            </div>
          </div>
        </div>
      </div>
      <div>
        <telia-button
          v-if="!isEditing"
          variant="secondary"
          type="button"
          size="md"
          @click="handleCompleteStep"
        >
          {{ t("STEPPER.NEXT_STEP") }}
        </telia-button>
        <telia-button
          v-if="isEditing"
          variant="secondary"
          type="button"
          size="sm"
          @click="handleCompleteStep"
        >
          {{ t("STEPPER.SAVE") }}
        </telia-button>
      </div>
    </div>
    <div v-if="!active && completed" class="summary-wrapper">
      <div>
        <telia-p>{{ address?.attention }}</telia-p>
        <telia-p>{{ address?.businessName }}</telia-p>
        <telia-p>{{ address?.street }} {{ address.streetNumber }}</telia-p>
        <telia-p>{{ address?.postalCode }} {{ address?.city }}</telia-p>
      </div>
      <div>
        <telia-button variant="secondary" size="sm" @click="handleEditAddress">
          {{ t("STEPPER.EDIT") }}
        </telia-button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { translate as t } from "@telia/b2b-i18n";
import { ref, computed, toRefs, watchEffect } from "vue";
import { BasketSubscriptionLineUI, SearchResponseDTO } from "../../data-types";
import { StructuredAddress } from "../../data-types/DeliveryInfo";
import { Delivery } from "../../data-types/DeliveryInfo";
import { formatMsisdn } from "../../helpers/number-formatter.service";
import { useDeliveryInfoStore } from "../../store";
import "@telia/b2b-address-search";
import "@telia/b2b-autocomplete-input";

interface AdditionalAddressInformation {
  additionalAddressInformation: string;
}

type AddressInformation = SearchResponseDTO & AdditionalAddressInformation;
interface Props {
  loadingAddress: boolean;
  scopeId: string;
  active: boolean;
  isEditing: boolean;
  defaultAddress?: StructuredAddress;
  address: Delivery;
  showPackageReceiver: boolean;
  subscriptionDeliveries: BasketSubscriptionLineUI[];
  showEmnDeliveryInfo: boolean;
  activatedSubscriptionsIcc: BasketSubscriptionLineUI[];
}
const props = defineProps<Props>();

const emit = defineEmits<{
  (e: "completeStep"): void;
  (e: "handleEditAddress"): void;
  (e: "handleSelectAddress", address: Delivery): void;
}>();

const { loadingAddress, defaultAddress, address, active, scopeId } = toRefs(props);

const deliveryStore = useDeliveryInfoStore();
const completed = ref<boolean>(false);
const attention = ref<string>("");
const render = computed(() => {
  return !loadingAddress.value;
});

function handleCompleteStep() {
  if (
    !address.value.businessName ||
    !address.value.street ||
    !address.value.postalCode ||
    !address.value.city
  ) {
    return;
  }

  deliveryStore.setDeliveryAddress(address.value);
  deliveryStore.setDeliveryAttention(attention.value);
  emit("completeStep");
  completed.value = true;
}
function handleEditAddress() {
  emit("handleEditAddress");
}

function setOrganisationName(value: string) {
  address.value.businessName = value;
  emit("handleSelectAddress", { ...address.value, businessName: value });
}
function handleModeChanged(event: "MANUAL" | "SEARCH") {
  deliveryStore.setDeliveryAddressMode(event);
}

function setAttention(value: string) {
  attention.value = value;
}
function handleAddressSelected(event: AddressInformation | null) {
  if (!event) {
    emit("handleSelectAddress", {
      ...address.value,
      street: "",
      streetNumber: "",
      postalCode: "",
      city: "",
      entrance: "",
    });
  } else {
    emit("handleSelectAddress", {
      ...address.value,
      street: event.streetName ?? "",
      streetNumber: event.streetNumber ?? "",
      postalCode: event.postalCode ?? "",
      city: event.city ?? "",
      entrance: event.entrance ?? "",
    });
  }
}
</script>

<style lang="scss" scoped>
@import "node_modules/@teliads/components/foundations/spacing/variables";
@import "node_modules/@teliads/components/foundations/colors/variables";
.delivery-address {
  &__skeletons {
    display: flex;
    flex-direction: column;
    gap: $telia-spacing-24;
    > * {
      height: 4rem;
    }
  }
  &__inline {
    display: flex;
    gap: $telia-spacing-12;
    padding: $telia-spacing-4 0;
    padding-left: $telia-spacing-12;
  }
  &__color-gray {
    color: $telia-gray-700;
  }
  &__package-info {
    display: flex;
    flex-direction: column;
    gap: $telia-spacing-16;
    &__receiver-input {
      display: flex;
      flex-direction: column;
      gap: $telia-spacing-24;
    }
  }
  &__icc-info {
    display: flex;
    flex-direction: column;
    gap: $telia-spacing-8;
  }
}
.delivery-address-active {
  display: flex;
  flex-direction: column;
  gap: $telia-spacing-24;
}
.summary-wrapper {
  display: flex;
  flex-direction: column;
  gap: $telia-spacing-24;
}
</style>
