<template>
  <div>
    <div class="invoice-per-combined-items">
      <div class="invoice-per-combined-items__product-wrapper">
        <div>
          <telia-heading variant="title-100" tag="h2">
            {{ combinedItem?.hardware.product?.name }}
          </telia-heading>
          <telia-p>
            {{ combinedItem?.hardware.product?.memoryGb }} GB
            {{ combinedItem?.hardware.product?.color }}
          </telia-p>
        </div>
        <div>
          <telia-heading variant="title-100" tag="h2">
            {{ combinedItem?.subscription.product?.name }}
          </telia-heading>
          <telia-p>
            {{ combinedItem?.subscription.user?.name }} {{ combinedItem?.subscription.msisdn }}
          </telia-p>
        </div>
      </div>
      <div>
        <InvoiceSelector
          @selectedInvoice="handleSelectedAccount"
          :new-billing-accounts="deliveryStore.newBillingAccounts"
          :existing-billing-accounts="deliveryStore.existingAccounts"
        />
      </div>
    </div>
    <InvoiceAddressSummary
      v-if="deliveryStore.selectedBillingAccount"
      :is-new-account="isNewAccount"
      :selected-account-address="deliveryStore.selectedBillingAccount"
    />
  </div>
</template>

<script lang="ts" setup>
import { CombinedItem } from "../../data-types";
import { PropType, computed } from "vue";
import { useDeliveryInfoStore } from "../../store";
import InvoiceSelector from "./InvoiceSelector.vue";
import InvoiceAddressSummary from "./InvoiceAddressSummary.vue";

const props = defineProps({
  combinedItem: {
    type: Object as PropType<CombinedItem>,
  },
});

const deliveryStore = useDeliveryInfoStore();

const isNewAccount = computed(() => {
  return deliveryStore.getNewBillingAccounts.some(
    (account) => account.accountNumber === deliveryStore.selectedBillingAccount?.accountNumber
  );
});

function handleSelectedAccount(accountNumber: string | undefined) {
  if (
    accountNumber === undefined &&
    props.combinedItem?.subscription.id &&
    props.combinedItem?.hardware.id
  ) {
    deliveryStore.removeProductFromBillingAccountIds(props.combinedItem.subscription.id);
    deliveryStore.removeProductFromBillingAccountIds(props.combinedItem.hardware.id);
    return;
  }
  if (accountNumber) {
    deliveryStore.setBillingAccountIds(props.combinedItem?.hardware.id, accountNumber);
    deliveryStore.setBillingAccountIds(props.combinedItem?.subscription.id, accountNumber);
  }
}
</script>

<style lang="scss" scoped>
@import "node_modules/@teliads/components/foundations/colors/variables";
@import "node_modules/@teliads/components/foundations/spacing/variables";
.invoice-per-combined-items {
  display: flex;
  align-items: flex-end;
  gap: $telia-spacing-12;
  border-top: solid 1px $telia-gray-300;
  padding: $telia-spacing-32 0;
  :first-child {
    flex: 3;
  }
  :nth-child(2) {
    flex: 4;
  }
  &__product-wrapper {
    display: flex;
    flex-direction: column;
    gap: $telia-spacing-32;
  }
  &__invoice-address {
    padding: $telia-spacing-12 0 $telia-spacing-24 0;
    &__delivery-method {
      margin-top: $telia-spacing-16;
    }
  }
}
</style>
