import { LegalAddressStructuredSuggestionDTO } from "@telia/b2b-rest-client/dist/corp-address-lookup";
import { BasketSubscriptionLineUI } from "@telia/b2b-rest-client/dist/corp-product-order";
import { BillingAccountOnBasketItemRequestUI } from "@telia/b2b-rest-client/dist/corp-product-order/models/BillingAccountOnBasketItemRequestUI";

export enum Step {
  DeliveryAddress = 1,
  DeliveryContactInfo = 2,
  InvoiceAccount = 3,
  CheckOut = 4,
}

export type Delivery = {
  businessName: string;
  attention: string;
  street: string;
  streetNumber: string;
  entrance?: string;
  postalCode: string;
  city: string;
};
export type ContactDetails = {
  emailAddress: string;
  firstName: string;
  lastName: string;
  phone: string;
  reference?: string;
};

export type DeliveryInfo = {
  delivery: Delivery;
  contactDetails: ContactDetails;
  billingAccountId?: string;
  billingAccountIds?: BillingAccountOnBasketItemRequestUI[];
  language: "SV" | "EN";
};

// Props
export interface DeliveryInfoProps {
  scopeId: string;
  tscid: string;
}

export interface DeliveryAddressProps {
  loadingAddress: boolean;
  scopeId: string;
  active: boolean;
  isEditing: boolean;
  defaultAddress?: StructuredAddress;
  address: Delivery;
  showPackageReceiver: boolean;
  subscriptionDeliveries: BasketSubscriptionLineUI[];
  activatedSubscriptionsIcc: BasketSubscriptionLineUI[];
}

export interface DeliveryContactInfoProps {
  active: boolean;
  isEditing: boolean;
}

export type StructuredAddress = LegalAddressStructuredSuggestionDTO;
