import * as analytics from "@telia/b2b-web-analytics-wrapper";
import Big from "big.js";
import { useUserConfigStore } from "../store";

export function trackConfirmOrder(orderNumber, basket, billingAccountCount, manualShippingAddress) {
  const userConfigStore = useUserConfigStore();

  let payload = {
    pa: "purchase", // enhanced ecommerce action
    ta: "MyBusiness-Salesflow", // affiliation
    ti: orderNumber, // transaction ID (random GUID for this example)
    tr: 0, // transaction revenue, total order value
    ts: "0.00", // transaction shipping
    tt: "0.00", // transaction tax
    cu: "SEK", // teliaAnalytics requires Currency Code (cu) on clicks
    cd4: "MCO", // teliaAnalytics requires cd4 'ProductOffer', look into this
    cd9: "MCO", // teliaAnalytics requires cd9 'Hierarchy ID', look into this
    cd94: getAgreementType(),
  };

  const allProducts = {
    ...prepareSubscriptionLinesForGA(basket.lines || [], basket.hardwareLines),
    ...(prepareHardwareLinesForGA(basket.hardwareLines || [], basket.lines) || {}),
    ...prepareProcessLinesForGA(basket.processLines || []),
    ...prepareAccessoryLinesForGA(basket.accessoryLines || []),
    ...prepareSwitchboardUserLinesForGA(basket.switchboardUserLines || []),
  };

  let totalQuantity = 0;
  Object.keys(allProducts).forEach((productKey, i) => {
    const product = allProducts[productKey];
    const summedRecurringFee = calculateTotalRecurringFee(product);
    const totalUnitFee = new Big(product.oneTimeFee).plus(summedRecurringFee);

    payload = analytics.writeProductData(
      payload,
      { ...product, totalUnitFee: totalUnitFee.toNumber() },
      i + 1,
      product.quantity
    );
    if (product.trackingVariant) {
      payload[`pr${i + 1}va`] = product.trackingVariant;
    }
    if (product.trackingCC) {
      payload[`pr${i + 1}cc`] = product.trackingCC;
    }

    payload[`pr${i + 1}cm13`] = +product.recurringFee;
    payload[`pr${i + 1}cm14`] = +product.oneTimeFee;

    const commitment = getCommitmentForTracking(product);
    if (commitment || commitment === 0) {
      payload[`pr${i + 1}cd52`] = `${commitment} months`;
    }

    if (product.trackingOperation) {
      payload[`pr${i + 1}cd12`] = product.trackingOperation;
    }

    if (product.huntingGroups) {
      payload[`pr${i + 1}cd7`] = getHuntingGroups(product.huntingGroups);
    }

    totalQuantity += product.quantity;

    payload.tr = new Big(payload.tr).plus(new Big(totalUnitFee).times(product.quantity)).toNumber(); // transaction revenue, total order value
  });

  const addressInputMode = manualShippingAddress ? "address_manual" : "address_search";

  analytics.trackEvent(
    analytics.category.SALESFLOW,
    analytics.action.ORDER_COMPLETED,
    `${
      Object.keys(allProducts).length
    } products, ${totalQuantity} quantity, ${billingAccountCount} billing accounts, shipping ${addressInputMode}`,
    "0",
    Object.keys(payload).map((key) => ({
      type: key,
      value: payload[key],
    }))
  );
}

function getCommitmentForTracking(product) {
  if (product.commitment || product.commitment === 0) return product.commitment;

  if (product.trackingCommitment || product.trackingCommitment === 0)
    return product.trackingCommitment;

  return null;
}

function prepareSubscriptionLinesForGA(subscriptionLines, basketLines) {
  return subscriptionLines.reduce((acc, line) => {
    const hasRelatedHardware = !!basketLines?.find(
      (hwLine) => hwLine.relationId === line.relationId
    );

    const products = [line.product, ...(line.addons || []), ...(line.datasims || [])];
    if (line.mds) {
      products.push(line.mds);
    }
    products.forEach((product) => {
      let id = buildSubscriptionId(product);
      if (id in acc) {
        acc[id].quantity++;
      } else {
        product.trackingVariant = id;

        if (hasRelatedHardware) {
          product.trackingCC = "COMBINED_HARDWARE_SUBSCRIPTION_OFFER";
        }

        product.trackingOperation = isExtendOperation(product.operation) ? "extend" : "new";

        acc[id] = {
          ...product,
          quantity: 1,
        };
      }
    });
    return acc;
  }, {});
}

function buildSubscriptionId(product) {
  let id = product.productCode;
  if (product.commitment) {
    id += `-${product.commitment}_MONTHS`;
  }

  if (isExtendOperation(product.operation)) {
    id += "-EXTEND_COMMITMENT";
  }

  return id;
}

function prepareHardwareLinesForGA(hardwareLines, subscriptionLines) {
  return hardwareLines.reduce((acc, line) => {
    const relatedSubscription = subscriptionLines.find(
      (subLine) => subLine.relationId === line.relationId
    );
    const hasRelation = !!relatedSubscription;
    const isUpfront = line.upfrontPayment;
    const isExtend = isExtendOperation(relatedSubscription?.product?.operation);
    const id = buildHardwareId(line.product, hasRelation, isUpfront, isExtend);
    if (id in acc) {
      acc[id].quantity++;
    } else {
      if (hasRelation) {
        line.product.trackingCC = "COMBINED_HARDWARE_SUBSCRIPTION_OFFER";
        line.product.trackingCommitment = relatedSubscription.product.commitment;

        line.product.trackingOperation = isExtend ? "extend" : "new";
      }
      acc[id] = {
        ...line.product,
        quantity: 1,
      };
    }
    return acc;
  }, {});
}

function buildHardwareId(product, hasRelation, isUpfront, isExtend) {
  let id = product.productCode;
  if (hasRelation) {
    id += product.oneTimeFee;
    id += product.recurringFee;

    if (isUpfront) {
      id += "-UPFRONT";
    }
    if (isExtend) {
      id += "-EXTEND";
    }
  }
  return id;
}

function prepareProcessLinesForGA(processLines) {
  return processLines.reduce((acc, line) => {
    let id = buildProcessId(line);
    if (id in acc) {
      acc[id].quantity++;
    } else {
      line.product.trackingVariant = id;
      acc[id] = {
        ...line.product,
        quantity: 1,
      };
    }
    return acc;
  }, {});
}

function buildProcessId(line) {
  return `${line.product.productCode}-${line.currentOwnerType}`;
}

function prepareAccessoryLinesForGA(accessoryLines) {
  return accessoryLines.reduce((acc, line) => {
    if (line.product.productCode in acc) {
      acc[line.product.productCode].quantity++;
    } else {
      acc[line.product.productCode] = {
        ...line.product,
        quantity: 1,
      };
    }
    return acc;
  }, {});
}

function prepareSwitchboardUserLinesForGA(switchboardUserLine) {
  return switchboardUserLine.reduce((acc, line) => {
    const products = [line.product, ...(line.addons || [])];
    products.forEach((product) => {
      if (product.productCode in acc) {
        acc[product.productCode].quantity++;
      } else {
        acc[product.productCode] = {
          ...product,
          quantity: 1,
        };
      }
    });

    return acc;
  }, {});
}

function calculateTotalRecurringFee(product) {
  if (product.commitment) {
    return new Big(product.recurringFee).times(product.commitment);
  } else if (product.trackingCommitment) {
    return new Big(product.recurringFee).times(product.trackingCommitment);
  } else {
    return new Big(product.recurringFee);
  }
}

function isExtendOperation(operation) {
  return operation === "EXTEND_SUBSCRIPTION";
}

function getHuntingGroups(huntingGroups) {
  return "Number of hunting groups: " + (huntingGroups?.length | 0);
}

function getAgreementType() {
  const userConfigStore = useUserConfigStore();
  if (userConfigStore.hasNfa === undefined) {
    return "Unkown";
  }
  return userConfigStore.hasNfa ? "New Frame Agreement" : "MFA/RAM";
}
