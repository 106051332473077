import { match } from "path-to-regexp";
interface Match {
  params: Params;
}
interface Params {
  tscid: string;
  scopeId: string;
}

export function getTscidFromUrl(url = window.location.pathname): string {
  const { params } = match("/foretag/mybusiness/:scopeId/bestall/:tscid/checka-ut")(url) as Match;

  return params.tscid;
}
