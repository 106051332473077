import { defineStore } from "pinia";
import { useBasketStore, useUserConfigStore } from "./index";
import {
  AddBillingAccountDTO,
  BillingAccountOnBasketItemRequestUI,
  ContactDetails,
  Delivery,
  DeliveryInfo,
  GetBillingAccountDTO,
} from "../data-types";
import { trackDeliveryAddressInput } from "../helpers/stepperTracking";
import { trackPaymentInfo } from "../helpers/tracking/gaHelper";
export const useDeliveryInfoStore = defineStore("deliveryInfo-store", {
  state: () => {
    return {
      deliveryInfo: {} as DeliveryInfo,
      existingAccounts: [] as GetBillingAccountDTO[],
      billingAccountId: undefined as string | undefined,
      billingAccountIds: [] as BillingAccountOnBasketItemRequestUI[],
      newBillingAccounts: [] as GetBillingAccountDTO[],
      invoiceSelection: "gathered" as "gathered" | "separated",
      addressMode: "SEARCH" as "MANUAL" | "SEARCH",
    };
  },
  getters: {
    invoiceAccount: (state) => state.billingAccountId,
    getExistingBillingAccounts: (state) => state.existingAccounts,
    getbillingAccountId: (state) => state.billingAccountId,
    getBillingAccountIds: (state) => state.billingAccountIds,
    getNewBillingAccounts: (state) => state.newBillingAccounts,
    getInvoiceSelection: (state) => state.invoiceSelection,
    getAddressSection: (state) => state.deliveryInfo.delivery,
    getContactSection: (state) => state.deliveryInfo.contactDetails,
    allAccounts: (state) => {
      return [...state.newBillingAccounts, ...state.existingAccounts];
    },
    selectedBillingAccount: (state) => {
      return [...state.newBillingAccounts, ...state.existingAccounts].find(
        (account) => account.accountNumber === state.billingAccountId
      );
    },
    getAddressMode: (state) => state.addressMode,
  },
  actions: {
    setContactDetails(contactDetails: ContactDetails) {
      this.deliveryInfo.contactDetails = contactDetails;
    },
    setDeliveryAttention(attention: string) {
      this.deliveryInfo.delivery.attention = attention;
    },
    setDeliveryAddress(delivery: Delivery) {
      this.deliveryInfo.delivery = delivery;
    },
    setBillingAccountId(billingAccountId: string | undefined) {
      this.billingAccountId = billingAccountId;
      const basketStore = useBasketStore();
      trackPaymentInfo(basketStore.allBasketItemsAsAnyBasketLineType);
    },

    setInvoiceSelection(selection: "gathered" | "separated") {
      this.setBillingAccountId(undefined);
      this.clearBillingAccountIds();
      this.invoiceSelection = selection;
    },

    setBillingAccountIds(itemId, accountId: string) {
      const index = this.billingAccountIds.findIndex((item) => item.itemBasketName === itemId);
      if (index === -1) {
        this.billingAccountIds.push({ itemBasketName: itemId, billingAccountId: accountId });
      } else {
        this.billingAccountIds[index].billingAccountId = accountId;
      }
      const basketStore = useBasketStore();
      trackPaymentInfo(basketStore.allBasketItemsAsAnyBasketLineType);
    },
    removeProductFromBillingAccountIds(itemId: string) {
      const index = this.billingAccountIds.findIndex((item) => item.itemBasketName === itemId);
      if (index !== -1) {
        this.billingAccountIds.splice(index, 1);
      }
    },
    clearBillingAccountIds() {
      this.billingAccountIds = [];
    },
    addNewBillingAccount(account: AddBillingAccountDTO) {
      const newAccount = mapAddToGetBillingAccountDTO(account);
      this.newBillingAccounts.push(newAccount);
    },

    setLanguage(language: "SV" | "EN") {
      this.deliveryInfo.language = language;
    },
    setExistingBillingAccounts(accounts: GetBillingAccountDTO[]) {
      this.existingAccounts = accounts;
    },
    setDeliveryAddressMode(mode: "MANUAL" | "SEARCH") {
      this.addressMode = mode;
      trackDeliveryAddressInput(mode === "MANUAL");
    },
  },
});

function mapAddToGetBillingAccountDTO(account: AddBillingAccountDTO): GetBillingAccountDTO {
  return {
    category: account.category || "MOBILE",
    name: account.name,
    customerNumber: setCustomerNumber(),
    number: account.number || "",
    accountReference: account.accountReference || "",
    accountNumber: account.accountNumber || "",
    coAddress: "",
    address: account.address || "",
    city: account.city || "",
    postalCode: account.postalCode || "",
    deliveryMethod: setDeliveryMethod(account),
    deliveryEmail: account.invoiceDeliveryEmail || undefined,
    tscid: account.tscid || "",
    subscriptionCount: 0,
    singleSubscriptionBilling: account.singleSubscriptionBilling || false,
  };
}

function setDeliveryMethod(account: AddBillingAccountDTO): GetBillingAccountDTO["deliveryMethod"] {
  return account.invoiceDeliveryEmail ? "EMAIL" : "POSTAL";
}

function setCustomerNumber() {
  const userConfigStore = useUserConfigStore();
  return userConfigStore.getCustomerNumber ?? "";
}
