<template>
  <div class="separated-invoice">
    <div v-for="(item, i) in combinedItems" :key="i">
      <InvoicePerCombinedItems :combined-item="item" />
    </div>
    <div v-for="item in subscriptions" :key="item.id">
      <InvoicePerProduct :name="item.product.name" :product-id="item.id">
        <telia-p>{{ item.user?.name }} {{ item.msisdn }}</telia-p>
      </InvoicePerProduct>
    </div>
    <div v-for="item in emnSubscriptions" :key="item.id">
      <InvoicePerProduct :name="item.product.name" :product-id="item.id">
        <telia-p>{{ t("CART.EMN.SITE", { site: item.product.siteName || "" }) }}</telia-p>
        <telia-p>{{ t("CART.EMN.QUANTITY", { quantity: item.quantity }) }}</telia-p>
      </InvoicePerProduct>
    </div>
    <div v-for="item in hardwares" :key="item.id">
      <InvoicePerProduct :name="item.product.name" :product-id="item.id">
        <telia-p>{{ item.product.memoryGb }} GB {{ item.product.color }}</telia-p>
      </InvoicePerProduct>
    </div>
    <div v-for="item in accessories" :key="item.id">
      <InvoicePerProduct :name="item.product.name" :product-id="item.id" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import InvoicePerProduct from "./InvoicePerProduct.vue";
import InvoicePerCombinedItems from "./InvoicePerCombinedItems.vue";
import { CombinedItem } from "../../data-types";
import {
  BasketAccessoryLineUI,
  BasketHardwareLineUI,
  BasketSubscriptionLineUI,
  BasketEmnSubscriptionLineUI,
} from "@telia/b2b-rest-client/dist/corp-product-order";
import { translateMixin } from "../../locale";

interface Props {
  accessories: BasketAccessoryLineUI[];
  subscriptions: BasketSubscriptionLineUI[];
  emnSubscriptions: BasketEmnSubscriptionLineUI[];
  hardwares: BasketHardwareLineUI[];
  combinedItems: CombinedItem[];
}

defineProps<Props>();
const t = translateMixin.methods.t;
</script>
