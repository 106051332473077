import { AgreementType, ga4tracking } from "@telia/b2b-ecommerce-tracking";
import { Step } from "../../data-types";
import { AnyBasketLineType, buildLinePayload, Line, totalProductPrice } from "./gaUtils";
import { useBasketStore } from "../../store";
import { useUserConfigStore } from "../../store";

export function trackCheckoutStepGa4(step: Number, basketItems: AnyBasketLineType<Line>[]) {
  switch (step) {
    case Step.DeliveryAddress:
      trackShipping(basketItems);
      break;
    case Step.DeliveryContactInfo:
      trackCheckoutProgress(basketItems);
      break;
    default:
    // TODO: Log error?
  }
}

export function trackShipping(basketItems: AnyBasketLineType<Line>[]) {
  const basketStore = useBasketStore();
  const products = basketItems
    .map((item) =>
      buildLinePayload(item, basketStore.subscriptionProductsBoughtWithHardware(item.relationId))
    )
    .flat();
  const totalPrice = totalProductPrice(products);
  ga4tracking.trackShippingInfo({
    agreementType: getAgreementType(),
    pageSegment: "mybusiness",
    isLoggedIn: true,
    siteVersion: "mc_online",
    stepLabel: "Leveransadress",
    stepNumber: 2,
    totalPrice,
    products,
  });
}

export function trackPaymentInfo(basketItems: AnyBasketLineType<Line>[]) {
  const basketStore = useBasketStore();
  const products = basketItems
    .map((item) =>
      buildLinePayload(item, basketStore.subscriptionProductsBoughtWithHardware(item.relationId))
    )
    .flat();
  const totalPrice = totalProductPrice(products);
  ga4tracking.trackPaymentInfo({
    agreementType: getAgreementType(),
    pageSegment: "mybusiness",
    isLoggedIn: true,
    siteVersion: "mc_online",
    stepLabel: "Fakturakonto",
    stepNumber: 4,
    paymentType: "invoice",
    products,
    totalPrice,
  });
}

export function trackCheckoutProgress(basketItems: AnyBasketLineType<Line>[]) {
  const basketStore = useBasketStore();

  const products = basketItems
    .map((item) =>
      buildLinePayload(item, basketStore.subscriptionProductsBoughtWithHardware(item.relationId))
    )
    .flat();
  const totalPrice = totalProductPrice(products);
  ga4tracking.trackCheckoutProgress({
    agreementType: getAgreementType(),
    pageSegment: "mybusiness",
    isLoggedIn: true,
    siteVersion: "mc_online",
    stepLabel: "Kontakt och referens",
    stepNumber: 3,
    products,
    totalPrice,
  });
}

export function trackPurchase(orderId: string, basketItems: AnyBasketLineType<Line>[]) {
  const basketStore = useBasketStore();
  const products = basketItems
    .map((item) =>
      buildLinePayload(item, basketStore.subscriptionProductsBoughtWithHardware(item.relationId))
    )
    .flat();

  ga4tracking.trackPurchase({
    agreementType: getAgreementType(),
    isLoggedIn: true,
    listName: "",
    orderId,
    pageSegment: "mybusiness",
    products,
    siteVersion: "mc_online",
    totalPrice: Number(totalProductPrice(products)),
  });
}

function getAgreementType() {
  const userConfigStore = useUserConfigStore();
  if (userConfigStore.isMfa === undefined) {
    return AgreementType.NFA;
  }
  return userConfigStore.isMfa ? AgreementType.MFA : AgreementType.NFA;
}
