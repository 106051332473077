<template>
  <div
    :id="id"
    :class="`accordion-item ${open ? 'accordion-item__active' : 'accordion-item__closed'} ${
      showOverflow ? 'show-overflow' : ''
    }`"
  >
    <div :class="`body ${open ? 'body__active' : 'body__closed'}`">
      <div class="body__content">
        <slot />
      </div>
    </div>
    <div
      v-if="showCaret"
      @click="toggle"
      @keyup.enter="toggle"
      class="show-more-wrapper"
      :data-testid="`toggle-button-${id}`"
    >
      <telia-p>{{ open ? t("ACCORDION.HIDE") : t("ACCORDION.SHOW_MORE") }}</telia-p>
      <div :class="`${open ? 'chevron' : 'chevron__open'}`">
        <telia-icon size="sm" name="chevron-up" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { translateMixin } from "../../locale";
defineProps({
  id: {
    type: String,
    required: true,
  },
  title: {
    type: String,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  showCaret: {
    type: Boolean,
    default: true,
  },
  showOverflow: {
    type: Boolean,
    default: false,
  },
});
const t = translateMixin.methods.t;
const open = ref<boolean>(false);

function toggle() {
  open.value = !open.value;
}
</script>

<style lang="scss" scoped>
@import "node_modules/@teliads/components/foundations/colors/variables";
@import "node_modules/@teliads/components/foundations/spacing/variables";

.accordion-item {
  transition: all 0.2s ease-in;
  overflow: hidden;
}
.accordion-item:hover {
  cursor: pointer;
}
.label-wrapper {
  padding-top: $telia-spacing-12;
}
.heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.subtitle {
  transition: transform 0.2s;
  transition-timing-function: ease-in;
  transform: translateZ(0);
  &__active {
    display: none;
    transform: translateY($telia-spacing-8) translateZ(0);
  }
  &__closed {
    display: block;
  }
}
.chevron {
  transition: transform 0.3s ease;
  &__open {
    transform: rotateX(180deg);
  }
}
.body {
  display: grid;
  grid-template-rows: 0fr;
  transition: grid-template-rows 0.25s ease-in-out;

  &__active {
    grid-template-rows: 1fr;
    padding-bottom: $telia-spacing-12;
    cursor: default;
  }
  &__content {
    overflow: hidden;
  }
}
.show-overflow {
  overflow: visible;
}
.show-more-wrapper {
  display: flex;
  gap: $telia-spacing-8;
  align-items: center;
  color: $telia-purple-700;
}
</style>
