<template>
  <div class="combined-items">
    <CartHardwareItem :item="combinedItemWithPrice.hardware" combined-item />
    <div class="link-wrapper">
      <div class="link-wrapper--item">
        <div class="link-wrapper--line"></div>
        <telia-icon name="link" size="sm" />
        <div class="link-wrapper--line"></div>
      </div>
    </div>
    <CartSubscriptionItem
      :item="combinedItemWithPrice.subscription"
      combined-item
      :increased-fee="combinedItemWithPrice.hardware.recurringFee"
    />
  </div>
</template>

<script setup lang="ts">
import CartHardwareItem from "./CartHardwareItem.vue";
import CartSubscriptionItem from "./CartSubscriptionItem.vue";
import { CombinedItem } from "../../data-types";
import { computed } from "vue";

interface Props {
  combinedItems: CombinedItem;
}

const props = defineProps<Props>();

const combinedItemWithPrice = computed((): CombinedItem => {
  const { hardware, subscription } = props.combinedItems;

  let subscriptionRecurringFee = subscription.recurringFee;
  if (hardware.recurringFee && subscription.recurringFee) {
    subscriptionRecurringFee = String(
      Number(subscription.recurringFee) + Number(hardware.recurringFee)
    );
  }

  let hardwareOneTimeFee = hardware.oneTimeFee;
  let subscriptionOneTimeFee = subscription.oneTimeFee;
  if (hardware.oneTimeFee && subscription.product.commitment > 0) {
    subscriptionOneTimeFee = String(Number(subscription.oneTimeFee) + Number(hardware.oneTimeFee));
    hardwareOneTimeFee = "";
  }

  return {
    subscription: {
      ...subscription,
      oneTimeFee: subscriptionOneTimeFee,
      recurringFee: subscriptionRecurringFee,
    },
    hardware: {
      ...hardware,
      oneTimeFee: hardwareOneTimeFee,
    },
  };
});
</script>

<style lang="scss" scoped>
@import "node_modules/@teliads/components/foundations/spacing/variables";
@import "node_modules/@teliads/components/foundations/colors/variables";
.combined-items {
  display: flex;
  flex-direction: column;
}
.link-wrapper {
  display: flex;
  color: $telia-gray-500;
  &--item {
    width: 6rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: calc($telia-spacing-20 * -1);
  }
  &--line {
    width: 1px;
    height: 2rem;
    background: $telia-gray-500;
  }
}
</style>
