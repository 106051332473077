<template>
  <div class="invoice-config">
    <div class="invoice-config__selection-wrapper">
      <b2x-radio-card
        data-testid="gathered-invoice-select"
        :checked="invoiceSelection === InvoiceOption.gathered"
        :value="InvoiceOption.gathered"
        :label="t('INVOICE_ACCOUNT.GATHERED_INVOICE')"
        @change="setInvoiceSelection(InvoiceOption.gathered)"
      />

      <b2x-radio-card
        data-testid="separated-invoice-select"
        :checked="invoiceSelection === InvoiceOption.separated"
        :value="InvoiceOption.separated"
        :label="t('INVOICE_ACCOUNT.SEPARATED_INVOICES')"
        :disabled="disableSeparateInvoices"
        @change="setInvoiceSelection(InvoiceOption.separated)"
      />
    </div>
    <div class="invoice-config__heading-button-wrapper">
      <telia-heading variant="title-200" tag="h2">
        {{
          invoiceSelection === InvoiceOption.gathered
            ? t("INVOICE_ACCOUNT.HEADING")
            : t("INVOICE_ACCOUNT.SEPARATED_INVOICES_HEADING")
        }}
      </telia-heading>
      <telia-button
        :left-icon="plusIcon"
        :text="t('INVOICE_ACCOUNT.NEW')"
        variant="secondary"
        size="sm"
        @click="handleNewInvoice"
      />
    </div>
    <GatheredInvoiceSelection v-if="invoiceSelection === InvoiceOption.gathered" />
    <SeparatedInvoiceSelection
      v-if="invoiceSelection === InvoiceOption.separated"
      :accessories="basketStore.accessories"
      :subscriptions="basketStore.subscriptions"
      :emn-subscriptions="basketStore.emnSubscriptions"
      :combined-items="basketStore.combinedItems"
      :hardwares="basketStore.hardwares"
    />
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { translateMixin } from "../../locale";
import { useDeliveryInfoStore, useBasketStore, useValidationStore } from "../../store";
import { trackInitiateCreateNewBillingAccount } from "../../helpers/stepperTracking";
import GatheredInvoiceSelection from "./GatheredInvoiceSelection.vue";
import SeparatedInvoiceSelection from "./SeparatedInvoiceSelection.vue";

type InvoiceSelected = "gathered" | "separated";

enum InvoiceOption {
  gathered = "gathered",
  separated = "separated",
}

const emit = defineEmits(["createNewInvoice"]);
const t = translateMixin.methods.t;
const plusIcon = JSON.stringify({ name: "plus", size: "sm" });

const validationStore = useValidationStore();
const deliveryInfoStore = useDeliveryInfoStore();
const basketStore = useBasketStore();

const invoiceSelection = computed(() => deliveryInfoStore.getInvoiceSelection);

const disableSeparateInvoices = computed(() => {
  const allLines = [
    ...basketStore.subscriptions,
    ...basketStore.combinedItems,
    ...basketStore.hardwares,
    ...basketStore.accessories,
  ].length;
  return basketStore.allBasketItems.length === 1 || allLines === 1;
});

function setInvoiceSelection(selection: InvoiceSelected) {
  validationStore.setInvoiceValidateState(false);
  deliveryInfoStore.setInvoiceSelection(selection);
}

function handleNewInvoice() {
  trackInitiateCreateNewBillingAccount();
  emit("createNewInvoice");
}
</script>

<style lang="scss" scoped>
@import "node_modules/@teliads/components/foundations/spacing/variables";
.invoice-config {
  display: flex;
  flex-direction: column;
  gap: $telia-spacing-32;

  &__selection-wrapper {
    display: flex;
    gap: $telia-spacing-12;
    width: 100%;
    > * {
      flex: 1;
    }
  }
  &__heading-button-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: $telia-spacing-24;
  }
}
</style>
