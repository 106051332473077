import { defineStore } from "pinia";
import { MobileUser } from "../data-types";

export const useUserConfigStore = defineStore("user-config-store", {
  state: () => {
    return {
      scopeId: null as string | null,
      tscid: null as string | null,
      customer: null as MobileUser | null,
      hasNfa: undefined as boolean | undefined,
      isMfa: undefined as boolean | undefined,
    };
  },
  getters: {
    getScopeId: (state) => state.scopeId,
    getTscid: (state) => state.tscid,
    getCustomerNumber: (state) => state.customer?.customerNumber,
    getCustomer: (state) => state.customer,
    getHasNfa: (state) => state.hasNfa,
  },
  actions: {
    setScopeId(scopeId: string) {
      this.scopeId = scopeId;
    },
    setTscid(tscid: string) {
      this.tscid = tscid;
    },
    setSelectedCustomer(customer: MobileUser) {
      this.customer = customer;
    },
    setHasNfa(hasNfa: boolean) {
      this.hasNfa = hasNfa;
    },
    setIsMfa(hasMfa: boolean) {
      this.isMfa = hasMfa;
    },
  },
});
