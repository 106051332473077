<template>
  <div class="cart" t-id="cart">
    <div class="product-wrapper">
      <telia-heading variant="title-100" tag="h2">
        {{ t("CART.HEADING") }}
      </telia-heading>

      <Notification
        v-if="hasUnavailableMsisdn"
        status="warning"
        :heading="t('CART.UNAVAILABLE_SUBSCRIPTIONS_HEADING')"
        :body="t('CART.UNAVAILABLE_SUBSCRIPTIONS_BODY')"
      />
      <CartProductList
        :hardwares="hardwares"
        :accessories="accessories"
        :combined-items="combinedItems"
        :subscriptions="subscriptions"
        :emnSubscriptions="emnSubscriptions"
      />
    </div>
    <CartPricing
      :total-one-time-fee="totalOneTimeFee"
      :total-recurring-fee="totalRecurringFee"
      :startup-fee="startupFee"
    />
    <b2x-loading-button
      variant="primary"
      type="submit"
      full-width
      :disabled="isAdmin"
      :is-loading="isSubmitting"
      :loading-label="t('STEPPER.CHECKING_OUT')"
      @click="submitOrder"
    >
      {{ t("STEPPER.CHECK_OUT") }}
    </b2x-loading-button>

    <Notification
      v-if="validationStore.getValidationError"
      status="information"
      :heading="t('CHECKOUT_WARNING.ALMOST_DONE')"
      :body="t(validationStore.getValidationError as string)"
    />
    <Notification
      v-if="checkoutFailed"
      status="warning"
      :heading="t('ERROR.HEADING')"
      :body="t('ERROR.CHECKOUT_FAILED')"
    />
    <Notification
      v-if="isAdmin"
      status="information"
      :heading="t('CHECKOUT_INFORMATION.NO_ADMIN_HEADER')"
      :body="t('CHECKOUT_INFORMATION.NO_ADMIN')"
    />
  </div>
</template>

<script setup lang="ts">
import { translate as t } from "@telia/b2b-i18n";
import {
  useBasketStore,
  useDeliveryInfoStore,
  useUserConfigStore,
  useValidationStore,
} from "../../store";
import { corpNumberBooking } from "@telia/b2b-rest-client";
import { useSubmitOrder } from "../../service/submitOrder";
import CartProductList from "./CartProductList.vue";
import CartPricing from "./CartPricing.vue";
import { storeToRefs } from "pinia";
import { computed } from "vue";
import Notification from "../ui-components/Notification.vue";
import { isTeliaAdmin } from "@telia/b2b-logged-in-service";

interface Props {
  scopeId: string;
  tscid: string;
  isAdmin: boolean;
}

const props = defineProps<Props>();
const basketStore = useBasketStore();
const validationStore = useValidationStore();

const {
  accessories,
  subscriptions,
  hardwares,
  combinedItems,
  emnSubscriptions,
  totalOneTimeFee,
  totalRecurringFee,
  startupFee,
} = storeToRefs(useBasketStore());
const deliveryInfoStore = useDeliveryInfoStore();
const { getScopeId, getTscid, getCustomerNumber } = useUserConfigStore();

const { isSubmitting, checkoutFailed, checkout } = useSubmitOrder();

const hasUnavailableMsisdn = computed(() => {
  return basketStore.unavailableSubscriptions.length;
});

async function submitOrder() {
  if (hasUnavailableMsisdn.value) return;
  validationStore.setInvoiceValidateState(true);
  const valid = validationStore.validateOrder();

  if (!valid) {
    return;
  }

  const billingAccountIds = deliveryInfoStore.getBillingAccountIds.length
    ? deliveryInfoStore.getBillingAccountIds
    : undefined;
  const billingAccountId = deliveryInfoStore.getbillingAccountId;
  let payload;
  if (deliveryInfoStore.getInvoiceSelection === "gathered") {
    payload = {
      ...deliveryInfoStore.deliveryInfo,
      billingAccountId: billingAccountId,
    };
  } else if (deliveryInfoStore.getInvoiceSelection === "separated") {
    payload = {
      ...deliveryInfoStore.deliveryInfo,
      billingAccountIds: billingAccountIds,
    };
  }
  basketStore.bookedNumbers.forEach((msisdn) => {
    corpNumberBooking.PublicNumberBookingControllerService.releaseReservedNumber(
      props.scopeId,
      props.tscid,
      msisdn
    );
  });
  await checkout(props.scopeId, props.tscid, payload);
}
</script>

<style lang="scss" scoped>
@import "node_modules/@teliads/components/foundations/spacing/variables";
@import "node_modules/@teliads/components/foundations/colors/variables";

.cart {
  display: flex;
  flex-direction: column;
  gap: $telia-spacing-48;
  background-color: $telia-white;
  padding: $telia-spacing-32;
  border-radius: 10px;
}
.product-wrapper {
  display: flex;
  flex-direction: column;
  gap: $telia-spacing-24;
}
.items-container {
  display: flex;
  flex-direction: column;
  gap: $telia-spacing-24;
}
</style>
