import { defineStore } from "pinia";
import { useStepperStore, useDeliveryInfoStore, useBasketStore } from "./index";
import { Step } from "../data-types";
export const useValidationStore = defineStore("validation-store", {
  state: () => {
    return {
      invoiceValidateState: false as boolean,
      validationError: undefined as string | undefined,
    };
  },
  getters: {
    getInvoiceValidateState: (state) => state.invoiceValidateState,
    getValidationError: (state) => state.validationError,
    getInvoiceCompleteStatus: () => {
      const deliveryInfoStore = useDeliveryInfoStore();
      const basketStore = useBasketStore();
      if (deliveryInfoStore.getInvoiceSelection === "gathered") {
        return deliveryInfoStore.getbillingAccountId !== undefined;
      }
      if (deliveryInfoStore.getInvoiceSelection === "separated") {
        return (
          deliveryInfoStore.getBillingAccountIds.length === basketStore.separatedInvoiceBasketLength
        );
      }
      return false;
    },
  },
  actions: {
    setInvoiceValidateState(invoiceValidateState: boolean) {
      this.invoiceValidateState = invoiceValidateState;
      if (!invoiceValidateState) {
        this.setValidationError(undefined);
      }
    },
    setValidationError(validationError: string | undefined) {
      this.validationError = validationError;
    },
    validateOrder() {
      const stepperStore = useStepperStore();
      const deliveryInfoStore = useDeliveryInfoStore();
      const basketStore = useBasketStore();

      if (stepperStore.currentStep !== Step.InvoiceAccount) {
        this.setValidationError("CHECKOUT_WARNING.COMPLETE_ALL_STEPS");
        return false;
      }
      if (stepperStore.editStep) {
        this.setValidationError(`CHECKOUT_WARNING.SAVE_STEP_${stepperStore.editStep}`);
        return false;
      }
      if (deliveryInfoStore.getInvoiceSelection === "gathered") {
        if (!deliveryInfoStore.getbillingAccountId) {
          this.setValidationError("CHECKOUT_WARNING.SELECT_INVOICE_ACCOUNT");
          return false;
        }
      } else if (deliveryInfoStore.getInvoiceSelection === "separated") {
        if (
          deliveryInfoStore.getBillingAccountIds.length !== basketStore.separatedInvoiceBasketLength
        ) {
          this.setValidationError("CHECKOUT_WARNING.SELECT_INVOICE_ACCOUNT_FOR_ALL_PRODUCTS");
          return false;
        }
      }
      this.setValidationError(undefined);
      return true;
    },
  },
});
