import {
  BasketUI,
  BasketSubscriptionLineUI,
  BasketSwitchboardUserLineUI,
  BasketHardwareLineUI,
  BillingAccountOnBasketItemRequestUI,
  GetBasketAddonUI,
  BasketAccessoryLineUI,
  BasketProcessLineUI,
  BasketEmnSubscriptionLineUI,
} from "@telia/b2b-rest-client/dist/corp-product-order";
import {
  DeliveryInfo,
  Delivery,
  DeliveryInfoProps,
  ContactDetails,
  Step,
  StructuredAddress,
} from "./DeliveryInfo";
import { GetBillingAccountDTO } from "@telia/b2b-rest-client/dist/corp-billing-accounts";
import { LegalAddressStructuredSuggestionDTO } from "@telia/b2b-rest-client/dist/corp-address-lookup";
import { MobileUser } from "@telia/b2b-rest-client/dist/corp-mobile-customer-service";
import type { SearchResponseDTO } from "@telia/b2b-rest-client/dist/corp-address-lookup";
import { AddBillingAccountDTO } from "@telia/b2b-rest-client/dist/corp-billing-accounts";
type CombinedItem = {
  subscription: BasketSubscriptionLineUI;
  hardware: BasketHardwareLineUI;
};
type BasketLineItem =
  | BasketSubscriptionLineUI
  | BasketHardwareLineUI
  | BasketAccessoryLineUI
  | BasketSwitchboardUserLineUI
  | BasketProcessLineUI
  | BasketEmnSubscriptionLineUI;

type Error = {
  message: string;
  status: "warning" | "error" | "information";
};

export function isBasketHardwareLine(item: BasketLineItem): item is BasketHardwareLineUI {
  return (item as BasketHardwareLineUI).product.memoryGb !== undefined;
}

export {
  AddBillingAccountDTO,
  BasketHardwareLineUI,
  BasketProcessLineUI,
  BasketSubscriptionLineUI,
  BasketSwitchboardUserLineUI,
  BasketEmnSubscriptionLineUI,
  BasketUI,
  BillingAccountOnBasketItemRequestUI,
  CombinedItem,
  ContactDetails,
  Delivery,
  DeliveryInfo,
  DeliveryInfoProps,
  Error,
  GetBasketAddonUI,
  GetBillingAccountDTO,
  LegalAddressStructuredSuggestionDTO,
  MobileUser,
  SearchResponseDTO,
  Step,
  StructuredAddress,
};
