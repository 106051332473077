<template>
  <div class="subscription-config-list">
    <section class="subscription-config-list__addons">
      <telia-heading variant="title-100" tag="h2">
        {{ t("CART.ADDONS") }}
      </telia-heading>
      <telia-p>{{ getCommitmentTime }}</telia-p>
      <telia-p v-if="simcard === 'SIMCARD'">
        {{ t("CART.NEW_SIM_CARD_FOR_PHONE") }}
      </telia-p>
      <div class="subscription-config-list__inline">
        <telia-p> {{ t("CART.STARTING_FEE") }} </telia-p>
        <telia-p>
          <strong> {{ formatPrice(oneTimeFee) }} {{ t("CART.SWEDISH_KRONA") }} </strong>
        </telia-p>
      </div>
      <div v-for="addon in addons || []" :key="addon.id" class="subscription-config-list__inline">
        <telia-p v-if="addon.name">{{ addon.name }}</telia-p>
        <telia-p>
          <strong>
            {{ t("CART.ADDON_COST_PER_MONTH", { price: formatPrice(addon.recurringFee) }) }}
          </strong>
        </telia-p>
      </div>
      <div v-for="datasim in datasims" class="subscription-config-list__inline">
        <telia-p>{{ datasim.name }}</telia-p>
        <telia-p>
          <strong>
            {{ t("CART.ADDON_COST_PER_MONTH", { price: formatPrice(datasim.recurringFee) }) }}
          </strong>
        </telia-p>
      </div>
      <div v-if="mds" class="subscription-config-list__inline">
        <telia-p>{{ mds.name }}</telia-p>
        <telia-p>
          <strong>
            {{ t("CART.ADDON_COST_PER_MONTH", { price: formatPrice(mds.recurringFee) }) }}
          </strong>
        </telia-p>
      </div>
    </section>

    <section v-if="switchboard">
      <telia-heading variant="title-100" tag="h2">
        {{ switchboard.product?.name }}
      </telia-heading>
      <div class="subscription-config-list__inline">
        <telia-p>{{ t("CART.MONTHLY_FEE") }}</telia-p>
        <telia-p>
          <strong>
            {{
              t("CART.ADDITIONAL_RECCURING_FEE", {
                price: formatPrice(switchboard.product?.recurringFee),
              })
            }}
          </strong>
        </telia-p>
      </div>
      <div
        v-for="addon in switchboard.addons || []"
        :key="addon.id"
        class="subscription-config-list__inline"
      >
        <telia-p v-if="addon.name">{{ addon.name }}</telia-p>
        <telia-p>
          <strong>
            {{ t("CART.ADDON_COST_PER_MONTH", { price: formatPrice(addon.recurringFee) }) }}
          </strong>
        </telia-p>
      </div>
    </section>

    <section v-if="combinedItem && totalPrice" class="subscription-config-list__combined-items">
      <div class="subscription-config-list__inline">
        <telia-p>{{ t("CART.INCREASED_PRICE_FOR_PHONE") }}</telia-p>
        <telia-p>
          <strong> {{ formatPrice(increasedFee) }} {{ t("CART.SWEDISH_KRONA_PER_MONTH") }} </strong>
        </telia-p>
      </div>
      <div class="subscription-config-list__total-price">
        <telia-heading variant="title-100" tag="h2"> {{ t("CART.TOTAL_COST") }}</telia-heading>
        <telia-p disable-hyphenation>
          {{ t("CART.MINIMUM_PRICE_FOR_SUBSCRIPTION", { price: totalPrice }) }}
        </telia-p>
        <telia-p disable-hyphenation>
          {{ t("CART.FEES_BY_FRAME_AGREEMENT") }}
        </telia-p>
      </div>
    </section>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { translate as t } from "@telia/b2b-i18n";
import {
  BasketSwitchboardUserLineUI,
  GetBasketAddonUI,
  GetBasketDatasimUI,
  GetBasketMdsUI,
  GetBasketSimCardUI,
} from "@telia/b2b-rest-client/dist/corp-product-order";

interface Props {
  addons: GetBasketAddonUI[];
  commitmentTime?: number;
  combinedItem?: boolean;
  oneTimeFee?: string;
  increasedFee?: string;
  totalPrice?: string;
  switchboard?: BasketSwitchboardUserLineUI;
  datasims: GetBasketDatasimUI[];
  mds?: GetBasketMdsUI;
  simcard?: GetBasketSimCardUI["type"];
}
const props = defineProps<Props>();

const formatPrice = (price: string | undefined) => {
  return price ? Math.floor(Number(price)) : "";
};
const getCommitmentTime = computed<string | null>(() => {
  if (props.commitmentTime === undefined) return null;
  return props.commitmentTime === 0
    ? t("CART.NO_COMMITMENT")
    : t("CART.COMMITMENT_TIME", { time: props.commitmentTime });
});
</script>

<style lang="scss" scoped>
@import "node_modules/@teliads/components/foundations/spacing/variables";
@import "node_modules/@teliads/components/foundations/colors/variables";
.subscription-config-list {
  display: flex;
  flex-direction: column;
  gap: $telia-spacing-24;

  &__addons {
    display: flex;
    flex-direction: column;
    gap: $telia-spacing-4;
  }
  &__combined-items {
    display: flex;
    flex-direction: column;
    gap: $telia-spacing-24;
  }
  &__inline {
    display: flex;
    justify-content: space-between;
  }
  &__total-price {
    display: flex;
    flex-direction: column;
    gap: $telia-spacing-4;
  }
  &__inline {
    display: flex;
    justify-content: space-between;
  }
}
</style>
