import { corpValidationService } from "@telia/b2b-rest-client";
import { useUserConfigStore } from "../store";

enum ValidationType {
  PHONE_NUMBER = "PHONENUMBER",
  EMAIL = "EMAIL",
}

export const validatePhoneNumber = async (phoneNumber: string) => {
  return await validate(ValidationType.PHONE_NUMBER, phoneNumber);
};

export const validateEmail = async (email: string) => {
  return await validate(ValidationType.EMAIL, email);
};
export const validateAndFormatPhoneNumber = async (phoneNumber: string) => {
  return await validateAndFormat(ValidationType.PHONE_NUMBER, phoneNumber);
};
const validate = async (type: ValidationType, value: string) => {
  const userConfigStore = useUserConfigStore();
  const scopeId = userConfigStore.getScopeId;
  try {
    const response = await corpValidationService.ValidationControllerService.validate(scopeId, {
      type,
      value,
    });

    return !!response;
  } catch (e) {
    return false;
  }
};

const validateAndFormat = async (type: ValidationType, value: string) => {
  const userConfigStore = useUserConfigStore();
  const scopeId = userConfigStore.getScopeId;
  try {
    const response = await corpValidationService.ValidationControllerService.validate(scopeId, {
      type,
      value,
    });
    return { valid: true, formattedNumber: response.formatedInput };
  } catch {
    return { valid: false, formattedNumber: undefined };
  }
};
