const formatNumberForView = (number: string): string => {
  return number?.replace(/^46/, "0");
};

const formatFixedNumber = (fixedNumber: string): string => {
  return fixedNumber?.substring(0, 2) === "46" ? `+${fixedNumber}` : fixedNumber;
};

const formatToFixedNumber = (number: string): string => {
  return number?.replace(/^(\+46|0046|0)/, "46").replace(/[\s-]/g, "");
};

const formatMsisdn = (msisdn: string): string => {
  return msisdn?.replace(/^[4][6](\d{2})(\d{3})(\d{2})(\d{2})$/, "0$1-$2 $3 $4");
};
const formatToMsisdn = (number: string): string => {
  return number?.replace(/^07/, "467").replace(/^\+/, "").replace(/^00/, "").replace(/[\s-]/g, "");
};

export {
  formatFixedNumber,
  formatToFixedNumber,
  formatMsisdn,
  formatToMsisdn,
  formatNumberForView,
};
